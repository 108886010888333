// =======================================================
// Streamchat
// =======================================================
import { DateTimeString } from '~/types/types';
import { NotSure } from '~/types/json-api/json-api';
import { createConstEnum } from '~/common/enum';
import { EnumType } from '~/types/enum/enum';
import { DrupalRole } from '~/types/drupal-common';

export const StreamChatRole = createConstEnum({
  ADMIN: 'admin',
  USER: 'user',
});

export type StreamChatRole = EnumType<typeof StreamChatRole>;

export const ChatMessageEntityType = createConstEnum({
  PURCHASE_REQUEST: 'purchase_request',
  NO_ENTITY: null,
});

export type ChatMessageEntityType = EnumType<typeof ChatMessageEntityType>

type LocalAttachmentType = {
  file_size?: number;
};
type LocalChannelType = Record<string, unknown>;
type LocalCommandType = string;
type LocalEventType = Record<string, unknown>;
type LocalMessageType = Record<string, unknown>;
type LocalReactionType = Record<string, unknown>;
type LocalUserType = {
  image?: string;
};

/**
 * @see https://getstream.io/chat/docs/sdk/react/guides/typescript_and_generics/
 */
export type StreamChatGenerics = {
  attachmentType: LocalAttachmentType;
  channelType: LocalChannelType;
  commandType: LocalCommandType;
  eventType: LocalEventType;
  messageType: LocalMessageType;
  reactionType: LocalReactionType;
  userType: LocalUserType;
};

export interface UserConnect {
  cid: string,
  connection_id: string,
  created_at: DateTimeString,
  me: {
    banned: boolean,
    channel_mutes: NotSure[],
    created_at: DateTimeString,
    devices: NotSure[],
    drupal_roles: DrupalRole[],
    id: string,
    invisible: boolean,
    language: string,
    last_active: DateTimeString,
    mutes: NotSure[],
    name: string,
    online: boolean,
    role: StreamChatRole,
    total_unread_count: number,
    unread_channels: number,
    unread_count: number,
    updated_at: DateTimeString
  },
  type: string,
}
