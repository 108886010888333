<template>
  <UlUnlisted
    class="container"
    :inline="true"
  >
    <li>
      <ProductCategoriesMobile />
    </li>
    <li
      v-for="(link,i) of links"
      :key="i"
    >
      <router-link :to="link.href">
        {{ link.text }}
      </router-link>
    </li>
  </UlUnlisted>
  <div id="the-mobile-menu-expanded-container" />
</template>

<script setup lang="ts">
const links = [
  { text: 'Sellers', href: '/stores' },
  { text: 'Blog', href: '/blog' },
];
</script>

<style scoped lang="scss">
:deep(a),
:deep(button) {
  display: inline-block;
  padding: var(--space-xs) 0;
  cursor: pointer;
}

:deep(a) {
  &:hover {
    filter: revert;
  }
}

:deep([role=menu]) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

* {
  color: white;
}
</style>
