<template>
  <component
    :is="hx"
    :class="classes"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">

const props = defineProps({
  hx: {
    required: true,
    type: Number,
  },
  classes: {
    required: false,
    type: String,
  },
});

const hx = `h${props.hx}`;
</script>
