import { useDrupalRestApi } from '~/composables/api/drupal-rest-api';
import { EitherAxiosResponseData } from '~/composables/api/api-common';
import { DrupalRole } from '~/types/drupal-common';
import { createConstEnum } from '~/common/enum';
import { EnumType } from '~/types/enum/enum';

const clientEndpointCredentials = {
  client_id: 'f32c50e5-3247-4331-b600-d26b9577cbad',
  client_secret: 'secret',
};

export const GrantType = createConstEnum({
  PASSWORD: 'password',
  REFRESH_TOKEN: 'refresh_token',
});
export type GrantType = EnumType<typeof GrantType>;

interface PostProps {
  grant_type: GrantType,
  username?: string,
  password?: string,
  refresh_token?: string,
}

/**
 * The /oauth/token response.data.
 */
export interface AuthResponse {
  token_type: string,
  expires_in: number,
  access_token: string,
  refresh_token: string,
}

/**
 * Creates form data from an object's props+val pairs.
 *
 * @param data - The data to be added to FormData. The prop name is used as the form key.
 */
const createFormData = (data: { [prop: string]: string }): FormData => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }

  return formData;
};

export const useOauth = () => {
  const drupalRestApi = useDrupalRestApi();

  return {
    doPost: async (options: PostProps): Promise<EitherAxiosResponseData<AuthResponse>> => {
      const baseProps = {
        ...options,
        ...clientEndpointCredentials,
      };

      const props = options.grant_type === GrantType.PASSWORD
        ? {
          ...baseProps,
          scope: Object.values(DrupalRole)
            .join(' '),
        }
        : baseProps;

      const formData = createFormData(props);

      // Display the key/value pairs
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }
      return drupalRestApi.post<AuthResponse>(
        '/oauth/token',
        formData,
      );
    },
  };
};
