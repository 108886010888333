import { useApiBaseUrlStore } from '~/store/api-base-url';

export const useCsrf = () => {
  const apiBaseUrlStore = useApiBaseUrlStore();
  return {
    fetchToken: async (): Promise<string> => {
      const csrfResponse = await fetch(`${apiBaseUrlStore.apiBaseUrl}/session/token`);
      return (Promise.resolve(csrfResponse.text()));
    },
  };
};
