<template>
  <input
    ref="phoneInput"
    type="tel"
    :data-error-num="errNum"
    :data-real-val="realVal"
    @input="handleInput"
  >
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import intlTelInputWithUtils, { Iti } from 'intl-tel-input';
import { type FormKitFrameworkContext } from '@formkit/core';

const phoneInput = ref<HTMLInputElement | null>(null);
let iti: Iti;

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const errNum = ref(-1);
const realVal = ref();

const handleInput = (event: Event) => {
  const inputValue = iti.getNumber();
  const isValid = iti.isValidNumber();
  realVal.value = inputValue;
  errNum.value = inputValue
    ? iti.getValidationError()
    : -1;

  // Since we cannot set validation-messages in here, and its
  // difficult or impossible to leverage defineExpose or
  // defineEmits, we are setting vals on data-attributes to
  // convey values.
  //
  // To convey a validation error, we empty the value of the
  // input. This allows us to leverage the "required" validation
  // from the parent to handle all possible validation errors,
  // and then just set the error message itself based on the
  // value of the error number data property.
  props.context.node.input(isValid
    ? inputValue
    : null);
};

onMounted(() => {
  if (phoneInput.value) {
    if (props.context.value) {
      phoneInput.value.value = props.context.value;
    }

    iti = intlTelInputWithUtils(phoneInput.value, {
      initialCountry: props.context.country || 'US',
      utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@24.5.0/build/js/utils.js',
      separateDialCode: true,
    });
  }
});
</script>
