<template>
  <div class="line" />
</template>

<style scoped lang="scss">
.line {
  position: relative;

  &:before {
    position: absolute;
    display: block;
    content: '';
    height: 2px;
    width: 100%;
    background-color: var(--color--brand-primary);
  }
}
</style>
