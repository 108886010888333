import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { RequestUrlComponents, useDrupalJsonApi } from '~/composables/api/drupal-json-api';
import { ConfigPageData, ConfigPageHcConfigurationData } from '~/types/json-api/config-page';
import { NodeFaq, NodeLegal } from '~/types/json-api/node';
import { EitherAxiosResponseData } from '~/composables/api/api-common';
import { JsonResponseIncluded, LongTextField } from '~/types/json-api/json-api';

interface QueryOptions {
  bundle: string,
  includes?: string[],
  uuid?: string,
  storeId?: string,
}

/**
 * Provides methods to interact with nodes via api.
 */
export const useConfigPageApi = () => {
  const drupalJsonApi = useDrupalJsonApi();

  /**
   * Fetches products via api.
   *
   * @param queryOptions - Options to pass into the query.
   */
  const fetchConfigPages = <T>(queryOptions: QueryOptions) => {
    const apiParams = new DrupalJsonApiParams()
      .addInclude(queryOptions.includes);

    // if (queryOptions.hasOwnProperty('storeId') && queryOptions.storeId) {
    //   apiParams.addFilter('stores.id', queryOptions.storeId, 'IN');
    // }

    const opts: RequestUrlComponents = {
      entityType: 'config_pages',
      bundle: queryOptions.bundle,
      params: apiParams,
    };

    // if (queryOptions.hasOwnProperty('uuid') && queryOptions.uuid) {
    //   opts.uuid = queryOptions.uuid
    // }

    return drupalJsonApi.get<T>(opts);
  };

  const fetchQueue = async <T>(queueBundle: string, field: string): Promise<EitherAxiosResponseData<JsonResponseIncluded<T>>> => pipe(
    // We cannot add an field_faq_queue.items relationship for
    // whatever reason, so we need to make a separate request
    // to fetch the items themselves.
    await fetchConfigPages<ConfigPageHcConfigurationData[]>({
      bundle: 'hc_configuration',
      includes: [
        field,
      ],
    }),
    E.match(
      async (e) => E.left(e),
      async (configResponse) => drupalJsonApi.get<T>({
        entityType: 'entity_subqueue',
        bundle: queueBundle,
        uuid: configResponse.data[0].relationships[field].data.id,
        trailing: 'items',
      }),
    ),
  );

  return {
    fetchConfigPages,

    fetchLandingPage: (refFieldName: string) => fetchConfigPages<ConfigPageData[]>({
      bundle: 'landing_pages',
      includes: [
        refFieldName,
        `${refFieldName}.field_blades`,
        `${refFieldName}.field_blades.field_media_image`,
        `${refFieldName}.field_blades.field_media_image.field_media_image`,
      ],
    }),

    fetchMarquee: async (): Promise<EitherAxiosResponseData<null | LongTextField>> => pipe(
      await fetchConfigPages<ConfigPageHcConfigurationData[]>({
        bundle: 'hc_configuration',
      }),
      E.map((results) => results.data[0].attributes.field_marquee),
    ),

    fetchFaq: async () => fetchQueue<NodeFaq[]>('faq', 'field_faq_queue'),

    fetchLegal: async () => fetchQueue<NodeLegal[]>('legal', 'field_legal_queue'),
  };
};
