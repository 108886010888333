import { createConstEnum } from '~/common/enum';
import { EnumType } from '~/types/enum/enum';

export function getCookie(name, cookies: string | null = null) {
  if (typeof cookies === 'undefined' && typeof document === 'undefined') {
    return null;
  }

  const theCookies = cookies || document.cookie;
  const cookieArr = theCookies.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export function deleteCookie(cookieName, path = '/', domain: string | undefined = undefined) {
  console.log('deleting ', cookieName);
  if (typeof document === 'undefined') {
    console.error('deleteCookie: document is undefined');
    return;
  }

  let cookieSetting = `${encodeURIComponent(cookieName)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; Secure`;
  if (domain) {
    cookieSetting += `; domain=${domain}`;
  }

  document.cookie = cookieSetting;
}

export const CookieAction = createConstEnum({
  DELETED: 'deleted',
  ADDED: 'added',
  CHANGED: 'changed',
});

export type CookieAction = EnumType<typeof CookieAction>;

export function watchCookie(cookieName: string, callback: (action: CookieAction, value: string | null) => void, interval: number = 1000) {
  if (typeof document === 'undefined') {
    return; // Exit the function if document is not available
  }

  let lastCookie = document.cookie;
  setInterval(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const currentCookie = document.cookie;
    if (currentCookie !== lastCookie) {
      const cookieValue = getCookie(cookieName);
      const lastValue = getCookie(cookieName, lastCookie);

      if (cookieValue !== lastValue) {
        if (cookieValue === null) {
          callback(CookieAction.DELETED, null);
        } else if (lastValue === null) {
          callback(CookieAction.ADDED, cookieValue);
        } else {
          callback(CookieAction.CHANGED, cookieValue);
        }
      }

      lastCookie = currentCookie;
    }
  }, interval);
}
