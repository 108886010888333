import { ModalState, useModalState } from '~/composables/states/modal-state';

export interface SimpleModalData {
  title: string,
  description?: string,
  removeDescription?: boolean,
  /**
   * Whether to visually hide the description.
   *
   * This may be useful in some cases for accessbility,
   * but it's likely that removeDescription will be
   * more appropriate in most situations. This prop
   * initially existed because it was thought that the
   * description was mandatory in Dialog from '@headlessui/vue'
   * but that does not appear to be the case.
   */
  hideDescription?: boolean,
  body?: string,
  buttonText?: string,
  state: ModalState
}

export const useModalData = () => ({
  simpleModal: reactive<SimpleModalData>({
    title: 'Title',
    description: 'This is the description.',
    removeDescription: false,
    hideDescription: false,
    body: 'This is the body.',
    buttonText: 'Okay',
    state: useModalState(),
  }),
  errorModal: reactive<SimpleModalData>({
    title: 'Whoops!',
    description: 'We encountered an error.',
    removeDescription: false,
    hideDescription: true,
    body: '<p>It looks like we encountered an unexpected error. Sorry about that! Please try again or <a href=\'/contact\'>contact support</a> so that we can help get the issue resolved, and provide as much detail as you can about the issue.</p>',
    buttonText: 'Okay',
    state: useModalState(),
  }),
});
