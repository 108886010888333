import axios, { AxiosRequestConfig } from 'axios';
import { ApiCommon } from '~/composables/api/api-common';
import { AbsoluteUrlString, GenericObject } from '~/types/types';

export type RestRequestData = GenericObject;

export const useDrupalRestApi = () => ({
  /**
   * Make a POST request.
   *
   * @param absoluteUrl - The absolute url of the request.
   * @param data        - The data to POST.
   * @param config      - Additional config to pass into axios.
   */
  post: <T>(absoluteUrl: AbsoluteUrlString, data: RestRequestData, config: AxiosRequestConfig = {}) => ApiCommon.axios.post<T>({
    axiosInstance: axios.create(),
    url: ApiCommon.url.completeAbsoluteUrl(absoluteUrl),
    data,
    config,
  }),

  /**
   * Make a PATCH request.
   *
   * @param absoluteUrl - The absolute url of the request.
   * @param data        - The data to POST.
   * @param config      - Additional config to pass into axios.
   */
  patch: <T>(absoluteUrl: AbsoluteUrlString, data: RestRequestData, config: AxiosRequestConfig = {}) => ApiCommon.axios.patch<T>({
    axiosInstance: axios.create(),
    url: ApiCommon.url.completeAbsoluteUrl(absoluteUrl),
    data,
    config,
  }),

  /**
   * Make a GET request.
   *
   * @param absoluteUrl - The absolute url of the request.
   * @param config      - Additional config to pass into axios.
   */
  get: <T>(absoluteUrl: AbsoluteUrlString, config: AxiosRequestConfig = {}) => ApiCommon.axios.get<T>({
    axiosInstance: axios.create(),
    url: ApiCommon.url.completeAbsoluteUrl(absoluteUrl),
    config,
  }),

  /**
   * Make a DELETE request.
   *
   * Per https://stackoverflow.com/questions/51069552/axios-delete-request-with-body-and-headers,
   * delete requests shouldn't technically have a body. If a payload is needed
   * then it must be passed on the config.data object. This may result in a
   * confusing case where you have config.data.data.{payload}.
   *
   * @param absoluteUrl - The absolute url of the request.
   * @param config      - Additional config to pass into axios. Data needs to be pass on the data prop.
   */
  delete: <T>(absoluteUrl: AbsoluteUrlString, config: AxiosRequestConfig = {}) => ApiCommon.axios.delete<T>({
    axiosInstance: axios.create(),
    url: ApiCommon.url.completeAbsoluteUrl(absoluteUrl),
    config,
  }),
});
