<template>
  <FullWidthSection class="section">
    <OverlaySpinner
      v-if="spinner.isLoading()"
      :scoped="true"
    />

    <SimpleModal :data="erroModalData" />

    <BaseDynamicFormContainer
      class="form-container"
      :form-id="form.id"
    >
      <template #heading>
        <h2>Stay Up-to-Date with Our Latest Blog Posts</h2>
        <p>
          <template v-if="!submitted">
            Subscribe to our newsletter and receive the latest news and insights from
            <router-link to="/blog">
              our blog
            </router-link>
            straight to your inbox.
            Stay
            informed on all things Handcrafted and make sure that you never miss a post!
          </template>
          <template v-else>
            Thank you for signing up! We hope to make it worth your while.
          </template>
        </p>
      </template>
      <template
        v-if="!submitted"
        #form
      >
        <DynamicForm
          :form="form"
          @submitted="addToList($event.email, 10)"
        />
      </template>
      <template
        v-if="!submitted"
        #actions
      >
        <BaseActions
          :form-id="form.id"
          :show-cancel="false"
          submit-text="Sign Up"
        />
      </template>
    </BaseDynamicFormContainer>
  </FullWidthSection>
</template>

<script setup lang="ts">
import { required, TextField, Validator } from '@asigloo/vue-dynamic-forms';
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import { FormSchema } from '~/components/form/BaseDynamicFormContainer.vue';
import { useDrupalRestApi } from '~/composables/api/drupal-rest-api';
import OverlaySpinner from '~/components/spinners/OverlaySpinner.vue';
import { useLoadingState } from '~/composables/states/loading-state';
import SimpleModal from '~/components/modal/SimpleModal.vue';
import { useModalData } from '~/composables/modal';

const restApi = useDrupalRestApi();
const spinner = useLoadingState();
const erroModalData = useModalData().errorModal;

const submitted = ref(false);

const form: FormSchema = {
  id: 'updates-signup',
  fields: {
    email: TextField({
      label: 'Email',
      validations: [
        Validator({
          validator: required,
          text: 'The email is required.',
        }),
      ],
    }),
  },
};

/**
 * Adds a contact to the given list.
 *
 * Once this needs to be reused, break it out into
 * an API module.
 */
const addToList = async (email: string, listId: number) => {
  pipe(
    await spinner.errorableLoadWhile(() => restApi.post('/api/ac/add-to-list', {
      email,
      list_id: listId,
    })),
    E.match(
      () => {
        erroModalData.state.open();
      },
      () => {
        submitted.value = true;
      },
    ),
  );
};
</script>

<style lang="scss" scoped>
.section {
  background: var(--gradient--on-light--cool);
  color: white;
  position: relative;
}

.form-container {
  //font-size: var(--text-xl);
  padding: var(--space-lg) 0;
  width: 30em;
  max-width: 100%;
  margin: 0 auto;

  :deep(h2) {
    margin: 0 auto var(--space-sm);
    text-align: center;
  }

  :deep(p) {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
}

:deep(.heading) {
  margin: 0 auto;
}

:deep(form) {
  padding: 0;
  background: transparent;
  display: flex;
  flex-direction: column;

  gap: var(--space-xxs);
  width: var(--width--copy-max) !important;
  max-width: 100%;
  margin: 0 auto;

  .dynamic-input {
    flex: 1;
  }

  input {
    color: var(--color--copy-primary) !important;
    margin-bottom: 0 !important;
  }

  .actions {
    margin-top: 0;

    &, button {
      margin: 0;
    }
  }

  @media(min-width: 33em) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}

a {
  text-decoration: underline;

  &, &:hover {
    color: white;
  }
}

</style>
