import { defineStore } from 'pinia';

interface BaseUrlState {
  applicationBaseUrl: string | null;
}

export const platformShBackendPrefix = 'https://dashboard';
const localBackendPrefix = 'https://hc';

/**
 * Gets the api base url given the store's state.
 *
 * Theoretically this shouldn't be needed as we should
 * be able to call getters from other getters, but there
 * was a problem calling apiBaseUrl directly from apiBaseDomain
 * so we broke the logic out here.
 *
 * @param state
 */
const getApiBaseUrl = (state) => {
  const { applicationBaseUrl } = state;
  if (!applicationBaseUrl) {
    throw 'Could not make a request due to empty baseUrl.';
  }

  if (applicationBaseUrl.includes('localhost')
    || applicationBaseUrl.includes('ddev.site')) {
    // @todo: https://handcrafted-market.atlassian.net/browse/HC-264
    if (typeof process !== 'undefined') {
      process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
    }
    return `${localBackendPrefix}.ddev.site`;
  }

  const split = applicationBaseUrl.split('://');
  const appNoProtocol = split[1].replace('www.', '');
  // We always want to request via https. If the vue application is
  // hit at http then that is what will be stored, but the user
  // will actually be redirected to the https version of the site.
  return `${platformShBackendPrefix}.${appNoProtocol}`;
};

// export backendLink = (): (path: string) => string => {
//   const base = this.apiBaseDomain;
//   return (path: string) => {

//   };
// },

/**
 * Provides a store for the API base url.
 *
 * This store does not provide any actions because the value of
 * the applicationBaseUrl is set in main.ts as part of the SSR
 * initial state & state hydration. It should only be set there
 * and not anywhere else through the application since that is
 * the only place where it is reliably available.
 */
export const useApiBaseUrlStore = () => (defineStore({
  id: 'apiBaseUrl',
  state: (): BaseUrlState => ({
    applicationBaseUrl: null,
  }),
  actions: {
    backendUrl(path: string) {
      const pathWithoutSlash = path.startsWith('/')
        ? path.substring(1)
        : path;
      return `${this.apiBaseUrl}/${pathWithoutSlash}`;
    },
  },
  getters: {
    apiBaseUrl: (state) => getApiBaseUrl(state),
    isLocal: (state) => getApiBaseUrl(state)
      .includes(localBackendPrefix),

    /**
     * Gets the domain of the api base url.
     *
     * This is useful for e.g. setting cookies for the drupal site
     * from the vuejs app as long as they share the same main domain
     * and extension(s).
     *
     * @param state
     */
    apiBaseDomain: (state): string => {
      const baseUrl = getApiBaseUrl(state);
      return baseUrl
        .replace(`${platformShBackendPrefix}.`, '')
        .replace(`${localBackendPrefix}.`, '');
    },
  },
}))();
