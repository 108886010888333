import { defineStore } from 'pinia';
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { StreamChat } from 'stream-chat';
import { Errorable } from '~/composables/api/api-common';
import { useStreamChatApi } from '~/composables/api/non-commerce/stream-chat';
import { ChatMessageEntityType, UserConnect } from '~/types/stream-chat';

export interface ChatUserMetaData {
  token: string;
  userId: string;
  userName: string;
}

interface State {
  _userMetadata: O.Option<ChatUserMetaData>,
  _publicKey: O.Option<string>,
  _client: O.Option<StreamChat>,
  _connectUser: O.Option<UserConnect>,
  _isConnectingUser: boolean,
  unreadMessagesCount: number,
  // _unreadMessagesByEntity: { [key in ChatMessageEntityType]: number }
  unreadMessageCounts: Map<ChatMessageEntityType, number>
}

/**
 * Provides a store for the API base url.
 *
 * This store does not provide any actions because the value of
 * the applicationBaseUrl is set in main.ts as part of the SSR
 * initial state & state hydration. It should only be set there
 * and not anywhere else through the application since that is
 * the only place where it is reliably available.
 */
export const useStreamChatStore = () => {
  const streamChatApi = useStreamChatApi();

  const entityTypeKeys = () => Object.keys(ChatMessageEntityType) as (keyof typeof ChatMessageEntityType)[];

  const callForKeys = <T>(f: (k: keyof typeof ChatMessageEntityType) => void) => {
    for (const k of entityTypeKeys()) {
      f(k);
    }
  };

  const initUnreadMap = () => {
    const map = new Map<ChatMessageEntityType, number>();
    const keys = entityTypeKeys();
    for (const k of keys) {
      map.set(ChatMessageEntityType[k], 0);
    }
    return map;
  };

  return (defineStore({
    id: 'chatStore',
    state: (): State => ({
      _userMetadata: O.none,
      _publicKey: O.none,
      _client: O.none,
      _connectUser: O.none,
      _isConnectingUser: false,
      unreadMessagesCount: 0,
      unreadMessageCounts: initUnreadMap(),
    }),
    actions: {
      setUserMetadata(metadata: ChatUserMetaData) {
        this._userMetadata = O.some({
          token: metadata.token,
          userId: metadata.userId,
          userName: metadata.userName,
        });
      },
      setPublicKey(key: string) {
        this._publicKey = O.some(key);
      },
      setClient(client: StreamChat) {
        this._client = O.some(client);
        client.on((event) => {
          if (event.unread_count !== undefined) {
            this.unreadMessagesCount = event.unread_count;
            this.updateEntityUnreadCounts(client);
          }
        });
      },
      setConnectUserData(userConnect: UserConnect) {
        this._connectUser = O.some(userConnect);
        this.unreadMessagesCount = userConnect.me.unread_count;
      },
      updateEntityUnreadCounts(client: StreamChat) {
        callForKeys(async (type) => {
          const count = await streamChatApi.getUnreadCountByEntityType(client, ChatMessageEntityType[type]);
          this.unreadMessageCounts.set(ChatMessageEntityType[type], count);
        });
      },
    },
    getters: {
      async connectUser(state) {
        // Wait until _isConnectingUser is false
        await new Promise<void>((resolve) => {
          const waitForIsConnectingUser = () => {
            if (this._isConnectingUser !== true) {
              resolve();
            } else {
              setTimeout(waitForIsConnectingUser, 100);
            }
          };
          waitForIsConnectingUser();
        });

        this._isConnectingUser = true;

        if (O.isSome(this._connectUser)) {
          this._isConnectingUser = false;
          return this._connectUser.value;
        }

        const cpm = await this.clientPlusMetadata;
        if (E.isLeft(cpm)) {
          this._isConnectingUser = false;
          return E.left(cpm);
        }

        const response = await streamChatApi.connectUser(
          cpm.right.client,
          cpm.right.metadata.userId,
          cpm.right.metadata.token,
        );
        this.setConnectUserData(response);
        this._isConnectingUser = false;

        return E.right(response);
      },
      async clientPlusMetadata(state): Promise<Errorable<{
        metadata: ChatUserMetaData,
        client: StreamChat
      }>> {
        const [metadataResult, clientResult] = await Promise.all([this.userMetadata, this.client]);

        if (E.isLeft(metadataResult)) {
          return metadataResult;
        }

        if (E.isLeft(clientResult)) {
          return clientResult;
        }

        return E.right({
          metadata: metadataResult.right,
          client: clientResult.right,
        });
      },
      async client(state): Promise<Errorable<StreamChat>> {
        if (O.isSome(state._client)) {
          return E.right(state._client.value);
        }

        return pipe(
          await this.publicKey,
          E.map((key: string) => {
            const client: StreamChat = StreamChat.getInstance(key);
            this.setClient(client);
            return client;
          }),
        );
      },
      async publicKey(state): Promise<Errorable<string>> {
        if (O.isSome(state._publicKey)) {
          return E.right(state._publicKey.value);
        }

        return pipe(
          await streamChatApi.getPublicKey(),
          E.map(
            (key) => {
              // @ts-ignore
              this.setPublicKey(key);
              return key;
            },
          ),
        );
      },
      async userMetadata(state): Promise<Errorable<ChatUserMetaData>> {
        if (O.isSome(state._userMetadata)) {
          // @ts-ignore
          return E.right(state._userMetadata.value);
        }

        return pipe(
          await streamChatApi.getUserMetadata(),
          E.map(
            (tokenResponseData) => {
              const metadata = {
                token: tokenResponseData.token,
                userId: tokenResponseData.id,
                userName: tokenResponseData.name,
              };
              // @ts-ignore
              this.setUserMetadata(metadata);
              return metadata;
            },
          ),
        );
      },
    },
  }))();
};
