<template>
  <div class="actions">
    <BaseButton
      submit="true"
      :form="formId"
      :disabled="disableSubmit"
      @click="emitSubmit"
    >
      {{ submitText }}
    </BaseButton>
    <BaseButton
      v-if="secondaryText"
      class="secondary"
      submit="true"
      :form="formId"
      temperature="cool"
      @click="emitSecondary"
    >
      {{ secondaryText }}
    </BaseButton>
    <CancelButton
      v-if="showCancel"
      :form="formId"
      :unpad-x="true"
      temperature="ghost"
      @click="emitCancel"
    >
      {{ cancelText }}
    </CancelButton>

    <slot />
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  // A FormSchema ID, if applicable
  formId: {
    type: String || null,
    required: false,
    default: null,
  },
  submitText: {
    type: String,
    required: false,
    default: 'Submit',
  },
  disableSubmit: {
    type: Boolean,
    required: false,
    default: false,
  },
  secondaryText: {
    type: String,
    required: false,
  },
  showCancel: {
    type: Boolean,
    required: false,
    default: true,
  },
  cancelText: {
    type: String,
    required: false,
    default: 'Cancel',
  },
});

const emit = defineEmits([
  'submit',
  'submitSecondary',
  'cancel',
]);

const emitSubmit = () => emit('submit');
const emitSecondary = () => emit('submitSecondary');
const emitCancel = () => emit('cancel');

</script>

<style scoped lang="scss">
.actions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: var(--space-xs);

  //> *,
  //:deep(button),
  //:deep(a) {
  //  margin-bottom: var(--space-sm);
  //
  //  &:not(:last-child) {
  //    margin-right: var(--space-sm);
  //  }
  //}
}
</style>
