<template>
  <div
    class="list"
    :class="allowedColsClasses.join(' ')"
  >
    <HeadingHx
      v-if="heading"
      :hx="hx"
      class="h2"
      :classes="[
        hideHeading ? 'offscreen' : '',
        'title',
      ].join(' ')"
    >
      {{ heading }}
    </HeadingHx>
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  hx: {
    required: true,
    type: Number,
  },
  heading: {
    required: false,
    type: String,
    default: undefined,
  },
  hideHeading: {
    required: false,
    type: Boolean,
    default: false,
  },
  maxCols: {
    required: false,
    type: Number,
    default: 4,
  },
});

const allowedColsClasses = [];
for (let i = 1; i <= props.maxCols; i++) {
  allowedColsClasses.push(`allowCols--${i}`);
}

if (props.maxCols === 1) {
  allowedColsClasses.push('allowedCols--1only');
}

</script>

<style lang="scss" scoped>
:deep(ul) {
  display: grid;
  flex-flow: row wrap;
  gap: var(--space-md);
  grid-template-columns: 1fr;

  @at-root .allowedCols--1only & {
    grid-template-columns: 1fr;
  }

  @at-root .allowCols--3 & {
    @media(min-width: 390px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  //Think this was commented out to prevent product listings
  //from having 3 columns. This whole system needs to be better
  //implemented to allow better flexibility since, e.g. blog
  //listing page has different breakpoint requirements than
  //product listings.
  @at-root .allowCols--3 & {
    @media(min-width: 550px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @at-root .allowCols--4 & {
    @media(min-width: 1000px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

// Needed for scoped loading spinners
.list {
  position: relative;
  min-height: 15em;
}

.allowedCols--1only {
  max-width: var(--width--copy-max);
  margin: 0 auto;
}

</style>
