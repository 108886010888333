<template>
  <!-- To ensure lack of document does not break SSR -->
  <template v-if="isOpen">
    <Dialog
      :open="isOpen"
      @close="emitClose"
    >
      <DialogOverlay />
      <div class="modal-contents">
        <DialogTitle>{{ title }}</DialogTitle>
        <DialogDescription
          v-if="description && !removeDescription"
          :class="{offscreen: hideDescription}"
        >
          {{ description }}
        </DialogDescription>
        <slot />
      </div>
    </Dialog>
  </template>
</template>

<script setup lang="ts">
// @ts-ignore
import {
  Dialog, DialogDescription, DialogOverlay, DialogTitle,
} from '@headlessui/vue';

// @see interface SimpleModalData
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: false,
    default: undefined,
  },
  removeDescription: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideDescription: {
    type: Boolean,
    required: false,
    default: false,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close', 'open']);
const emitClose = () => emit('close');
const emitOpen = () => emit('open');

watch(props, (updated, old) => {
  if (updated.isOpen === true && old.isOpen === false) {
    emitOpen();
  }
});

</script>

<style lang="scss">
[id^=headlessui-dialog-overlay] {
  height: 10000vh;
  width: 100vw;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 100;
  pointer-events: none;
}

#headlessui-portal-root [role=dialog] .modal-contents {

  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  background: white;
  /*border: solid 1px var(--color--copy-deemphasized);*/
  /*box-shadow: var(--color--box-shadow) 0 0 .5em 0;*/
  border-radius: var(--border-radius--standard);
  padding: var(--space-std) var(--space-md);
  pointer-events: initial;
  z-index: 101;
  width: 100vw;

  // copy max width, but it's a css var so
  // can't use media query.
  $width: 600px;
  @media (min-width: #{$width}) {
    box-sizing: border-box;
    width: $width;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  //@media (min-width: 768px) {
  //  left: 50%;
  //  transform: translate(-50%, -50%);
  //}
}

</style>
