/**
 * @file
 *
 * By default, the content-type and access used by axios
 * are incompatible with POSTing via JSON:API. This
 * implementation should be used in place of regular
 * axios when making JSON:API requests.
 */

import axios from 'axios';

const instance = axios.create();

// https://github.com/axios/axios/issues/340#issuecomment-336223799
instance.defaults.headers['Content-Type'] = 'application/vnd.api+json';
instance.defaults.headers.Access = 'application/vnd.api+json';

export const axiosJsonApi = instance;
