<!--This component is a bit of a stub and should be-->
<!--made more flexible.-->
<template>
  <div class="item">
    <slot name="prefix" />
    <div class="contents">
      <slot />
    </div>
    <div class="suffix">
      <slot name="suffix" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.item {
  display: flex;
  gap: var(--space-xxxs);
  align-items: center;
}

.contents {
  margin-top: -.1em;
  line-height: 0;
}
</style>
