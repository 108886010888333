import { v4 as generateUuid } from 'uuid';
import { useStorage } from '@vueuse/core';
import { usePiniaUtils } from '~/composables/pinia';
import { CartParsed } from '~/composables/parsers';

interface CartState {
  cartToken: string
  carts: CartParsed[]
}

/**
 * Defines a cart store.
 *
 * When implementing the store, make sure to handle the null case as needed.
 * This is required because if we actually define the store during SSR
 * then we end up with all sorts of weird conflicts.
 */
export const useCartStore = () => {
  const pu = usePiniaUtils();
  return pu.initWindowDependentStore({
    id: 'cart',
    /**
     * It is important not to access the cartToken directly
     * as SSR will cause incorrect tokens to be populated
     * on initial load. Be sure to only access the value
     * using the token getter, which throws an error in this
     * scenario so that it can be remedied.
     */
    state: (): CartState => ({
      cartToken: useStorage<string>('cartToken', '', pu.maybeLocalstorage) as unknown as string,
      carts: useStorage<object>('carts', [], pu.maybeLocalstorage) as unknown as CartParsed[],
    }),
    actions: {
      /**
       * Generate a random cart token.
       */
      generateToken: (): string => generateUuid(),

      /**
       * Sets the token in the store and localStorage.
       *
       * @param token - The token.
       */
      setToken(token: string): void {
        // console.log('setting cart token');
        this.cartToken = token;
      },

      /**
       * Deletes the token from the store and localStorage.
       */
      deleteToken(): void {
        console.log('deleting cartToken');
        this.cartToken = '';
      },
    },
    getters: {
      tokenExists: (state): boolean => !!state.cartToken,

      tokenFromStorage: (_state): string | null => window.localStorage.getItem('cartToken'),

      itemPresentInCart: (state): (uuid: string) => string | null => (uuid: string) => {
        const cartsContainingItem = state.carts.filter((cart: CartParsed) => cart.orderItems.filter((item) => item.productId === uuid).length > 0);

        return cartsContainingItem.length > 0
          ? cartsContainingItem[0].id
          : null;
      },
    },
  });
};
