import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import {
  onBeforeMount, onMounted, onServerPrefetch, reactive, Ref, watchEffect,
} from 'vue';
import { pipe } from 'fp-ts/function';
import { removeTrailingChar } from '~/common/string';
import { useAuthStore } from '~/store/auth';

/**
 * Interface for the parameters used in the initRouteCache function.
 */
export interface CacheEntryMeta<T> {
  /**
   * A unique identifier. This is important even for path-based caching
   * since it avoids collisions between multiple caches for a single path.
   */
  id: any,

  /**
   * A function that returns a Promise resolving to the data to be cached.
   * This function is called when the cache does not have the required data
   * and is expected to fetch the data from an external source (e.g., an API call).
   */
  getter: () => Promise<T>,

  /**
   * A function that accepts the data fetched by the getter as its argument.
   * This function is responsible for updating the component state or store with the fetched data.
   */
  setter: (data: T) => void,

  /**
   * An optional function that is called after the setter function has been executed.
   * This can be used for any additional processing or clean-up operations.
   */
  after?: () => any,

  /**
   * A boolean that indicates if the auth scopes should be included in the cache key.
   * If true, the caching mechanism will consider different auth scopes as separate cache entries.
   */
  includeScopes: boolean,

  /**
   * A boolean that indicates if the caching process should be tied to the component's lifecycle.
   * If true, the caching is performed during the component's server prefetch and beforeMount hooks.
   */
  mountDependent: boolean,

  /**
   * Dynamic routes need special handling for navigation between variants.
   */
  watcher?: {
    /**
     * A function that unsets all of the values set by setter.
     *
     * This is useful for dynamic routes -- i.e. page-dir/[subpath].vue --
     * where navigating between one subpath and another needs to update
     * the page contents, but the component itself does not remount.
     */
    unsetter: () => void,

    /**
     * Values whose changes indicate dynamic cache handle triggers.
     */
    toWatch: Ref | ReturnType<typeof reactive>,

    /**
     * Whether to override includeScopes.
     */
    includeScopes?: boolean
  };
}

interface DataCacheState {
  data: Map<any, any>;
}

/**
 * Provides a store for the API base url.
 */
export const useDataCacheStore = () => {
  const route = useRoute();
  const authStore = useAuthStore();

  /**
   * Generates a unique key based on the route and other parameters.
   *
   * Maps should allow object keys, but there is an issue
   * in getByRoute() that the value is undefined when using
   * an object key instead of a string key.
   *
   * @param {boolean} isSsr - Indicates if the route is being rendered on the server.
   * @param meta - Metadata for the cache handler.
   * @returns {string} The unique route map key.
   */
  const routeMapKey = (isSsr: boolean, meta: CacheEntryMeta<any>) => JSON.stringify({
    id: meta.id,
    path: removeTrailingChar(route.path, '/'),
    isSsr,
    // Include scopes in the key in case the values differ,
    // such as access or pricing for wholesale items.
    scopes: meta.includeScopes
      ? authStore?.scopes || []
      : [],
  });

  return (defineStore({
    id: 'apiCache',
    state: (): DataCacheState => ({
      data: new Map(),
    }),
    actions: {
      /**
       * Sets the data in the cache for the current path.
       *
       * @param {any} data - The data to be set in the cache.
       * @param {boolean} ssr - Indicates if the route is being rendered on the server.
       * @param meta - Metadata for the cache handler.
       */
      setForCurrentPath<T>(data: any, ssr: boolean, meta: CacheEntryMeta<T>) {
        this.data.set(routeMapKey(ssr, meta), data);
      },
      /**
       * Retrieves the data from the cache for the current path.
       *
       * @param {boolean} ssr - Indicates if the route is being rendered on the server.
       * @param meta - Metadata for the cache handler.
       * @returns {any} The data retrieved from the cache.
       */
      getForCurrentPath<T>(ssr: boolean, meta: CacheEntryMeta<T>) {
        return this.data.get(routeMapKey(ssr, meta));
      },
      /**
       * Initializes the cache for the current path.
       *
       * If data is not set in the case, fetches the data and sets it in the cache.
       *
       * The initRouteCache function is designed to handle data fetching and caching
       * for a given path. It takes an object with the following properties as its argument:
       *
       * - getter: A function that returns a Promise resolving to the data to be cached.
       *           This function is called when the cache does not have the required data.
       *           It is expected to fetch the data from an external source (e.g., an API call).
       * - setter: A function that accepts the data fetched by the getter as its argument.
       *           This function is responsible for updating the component state or store
       *           with the fetched data.
       * - after: An optional function that is called after the setter function has been executed.
       *          This can be used for any additional processing or clean-up operations.
       * - includeScopes: A boolean that indicates if the auth scopes should be included
       *                  in the cache key. If true, the caching mechanism will consider
       *                  different auth scopes as separate cache entries.
       * - mountDependent: A boolean that indicates if the caching process should be tied
       *                   to the component's lifecycle. If true, the caching is performed
       *                   during the component's server prefetch and beforeMount hooks.
       *
       * @param {CacheEntryMeta<T>} params - The parameters for initializing the route cache.
       */
      async initPathCache<T>(params: CacheEntryMeta<T>) {
        const doCacheData = async (ssr: boolean, meta: CacheEntryMeta<any>) => {
          const pathData = await meta.getter();
          this.setForCurrentPath(pathData, ssr, meta);
          return pathData;
        };

        /**
         * Retrieves path data from the cache, or fetches and caches it.
         *
         * This function attempts to retrieve the path data in the following order:
         * 1. If available, it retrieves it from the server-side rendering (SSR) cache,
         *    which is populated during the first app hit from onServerPrefetch.
         * 2. If not in the SSR cache, it tries to get it from the client cache, which
         *    is relevant for repeat visits to a page that was not the first page loaded.
         * 3. If not in either the SSR or client cache, it fetches the data and sets it
         *    in the client cache, since it wasn't the initial page load and it hasn't
         *    been viewed yet during this session.
         *
         * @param meta - Metadata for the cache handler.
         * @returns {Promise<Object>} The path data from the cache or fetched from the server.
         */
        const getSetCachePathData = async (meta: CacheEntryMeta<any>) => {
          const ssrCacheData = this.getForCurrentPath(true, meta);
          // console.log({
          //   ssrCacheData,
          //   map: this.data,
          // });

          if (ssrCacheData) {
            // console.log('getting cached ssr');
            return ssrCacheData;
          }

          const clientCacheData = this.getForCurrentPath(false, meta);
          if (clientCacheData) {
            // console.log('getting cached client');
            return clientCacheData;
          }

          // console.log('caching client');
          return doCacheData(false, meta);
        };

        /**
         * Executes the given function and, if provided, calls the optional callback afterward.
         *
         * @param {() => Promise<any>} toDo - The function to execute.
         * @param {() => any} [then] - The optional callback to call after executing the function.
         */
        const doThen = async (toDo: () => Promise<any>, then?: () => any) => {
          await toDo();
          if (then) {
            then();
          }
        };

        /**
         * Handles client-side caching, either by fetching data from the cache or fetching and caching new data.
         */
        const handleClientCaching = async () => {
          await doThen(async () => pipe(
            await getSetCachePathData(params),
            params.setter,
          ), params.after);
        };

        // If the caching depends on mounting, call in onServerPrefetch and onBeforeMount hooks.
        if (params.mountDependent) {
          onServerPrefetch(async () => {
            await doThen(async () => pipe(
              await doCacheData(true, params),
              params.setter,
            ), params.after);
          });

          onBeforeMount(handleClientCaching);
        } else {
          await handleClientCaching();
        }

        // For dynamic routes, we'll need to watch the props
        // to ensure everything updates on navigation between
        // variants of "the same" route.
        if (params.watcher !== undefined) {
          onMounted(() => {
            if (params.watcher?.toWatch) {
              /**
               * Use watchEffect() instead of watch() since the latter does not
               * seem to effectively handle changes to the route/useRoute(),
               * i.e. when navigating between catchall routes in the same dir.
               */
              watchEffect(() => {
                // Need to set a var so that watchEffect picks up
                // on the value that we need to watch.
                // @ts-ignore
                const justToTriggerWatchEffect = params.watcher?.toWatch;
                // @ts-ignore
                params.watcher.unsetter();
                this.initPathCache({
                  ...params,
                  mountDependent: false,
                  // @ts-ignore
                  includeScopes: params.watcher.includeScopes || params.includeScopes,
                });
              });
            }
          });
        }
      },
    },
  }))();
};
