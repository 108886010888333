import axios from 'axios';
import useSWRV from 'swrv';
import { computed } from 'vue';

export interface SwrvSettings {
  fetcher?: any,
  options?: any,
  config?: any
}

/**
 * Wrapper around useSWRV.
 *
 * This primarily exists to allow us to provide config to axios
 * via the settings prop, but also provides additional flexibility
 * in caller implementations as well.
 *
 * @see https://github.com/Kong/swrv/issues/91
 * @param key - The request URL, used as the cache key.
 * @param settings - Additional options to be passed into the swrv request.
 */
export const swrvr = (key: string, settings: SwrvSettings = {}) => {
  if (!('config' in settings)) {
    settings.config = {};
  }

  if (!('fetcher' in settings) || !settings.fetcher) {
    settings.fetcher = (url: string) => axios.get(url, settings.config).then((res) => res.data);
  }
  if (!('options' in settings) || !settings.options) {
    settings.options = {};
  }
  if (!('shouldRetryOnError' in settings.options) || !settings.options.shouldRetryOnError) {
    settings.options.shouldRetryOnError = false;
  }
  if (!('revalidateOnFocus' in settings.options) || !settings.options.revalidateOnFocus) {
    settings.options.revalidateOnFocus = false;
  }
  const swr = useSWRV(key, settings.fetcher, settings.options);
  return {
    ...swr,
    isLoading: swr.isValidating,
    isLoaded: computed(() => swr.data.value !== undefined),
    reload: () => swr.mutate(),
  };
};
