/**
 * Convert a string to camelCase.
 */
export const toCamel = (s: string): string => s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
  .replace('-', '')
  .replace('_', ''));

/**
 * Capitalize The First Letter Of Every Word Of A String.
 *
 * @see https://www.digitalocean.com/community/tutorials/js-capitalizing-strings
 */
export const capitalizeWords = (s: string): string => s.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));

export const removeInitialChar = (string: string, char: string) => {
  const regex = new RegExp(`^${char}+`);
  return string.replace(regex, '');
};

export const removeTrailingChar = (string: string, char: string) => {
  const regex = new RegExp(`${char}+$`);
  return string.replace(regex, '');
};

export const trimChar = (string: string, char: string): string => removeInitialChar(removeTrailingChar(string, char), char);

/**
 * Pluralizes a string.
 *
 * Generated by chatgpt, hence the seemingly irrelevant rules,
 * though should be extensive.
 *
 * @param word
 *   The word to pluralize.
 */
export const pluralize = (word: string): string => {
  // Regular pluralization rules
  const pluralRules = [
    [/(m)an$/i, '$1en'], // man -> men
    [/(pe)rson$/i, '$1ople'], // person -> people
    [/(child)$/i, '$1ren'], // child -> children
    [/^(ox)$/i, '$1en'], // ox -> oxen
    [/(ax|test)is$/i, '$1es'], // axis, testis -> axes, testes
    [/(octop|vir)us$/i, '$1i'], // octopus, virus -> octopi, viri
    [/(alias|status)$/i, '$1es'], // alias, status -> aliases, statuses
    [/(bu)s$/i, '$1ses'], // bus -> buses
    [/(buffal|tomat|potat)o$/i, '$1oes'], // buffalo, tomato, potato -> buffaloes, tomatoes, potatoes
    [/(hive)$/i, '$1s'], // hive -> hives
    [/([^aeiouy]|qu)y$/i, '$1ies'], // cherry -> cherries
    [/(x|ch|ss|sh)$/i, '$1es'], // box, watch, kiss, dish -> boxes, watches, kisses, dishes
    [/(matr|vert|ind)(ix|ex)$/i, '$1ices'], // matrix, vertex, index -> matrices, vertices, indices
    [/([m|l])ouse$/i, '$1ice'], // mouse, louse -> mice, lice
    [/(quiz)$/i, '$1zes'], // quiz -> quizzes
    [/s$/i, 's'], // no change if already ends in s
    [/$/, 's'], // default: add s
  ];

  // Irregular words
  const irregulars = {
    foot: 'feet',
    tooth: 'teeth',
    goose: 'geese',
    cactus: 'cacti',
    focus: 'foci',
    fungus: 'fungi',
    nucleus: 'nuclei',
    syllabus: 'syllabi',
    analysis: 'analyses',
    diagnosis: 'diagnoses',
    oasis: 'oases',
    thesis: 'theses',
    crisis: 'crises',
    phenomenon: 'phenomena',
    criterion: 'criteria',
    datum: 'data',
  };

  // Uncountable words
  const uncountables = [
    'information',
    'rice',
    'money',
    'species',
    'series',
    'fish',
    'sheep',
    'deer',
  ];

  // Return word if it is uncountable
  if (uncountables.includes(word.toLowerCase())) {
    return word;
  }

  // Return irregular plural form if it exists
  for (const [singular, plural] of Object.entries(irregulars)) {
    if (new RegExp(`${singular}$`, 'i').test(word)) {
      return word.replace(new RegExp(`${singular}$`, 'i'), plural);
    }
  }

  // Apply regular pluralization rules
  for (const rule of pluralRules) {
    if (rule[0].test(word)) {
      return word.replace(rule[0], rule[1]);
    }
  }

  // Default case (should not reach here)
  return `${word}s`;
};
