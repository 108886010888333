<template>
  <button
    class="pill"
    :aria-pressed="isActive"
    @click="toggleIsActiveAndEmit"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import { defineEmits, ref } from 'vue';

const emit = defineEmits(['toggle']);

const isActive = ref(false);
const toggleIsActive = () => {
  isActive.value = !isActive.value;
};
const toggleIsActiveAndEmit = () => {
  toggleIsActive();
  emit('toggle', isActive.value);
};
</script>

<style scoped lang="scss">
.pill {
  border: solid 1px var(--color--border);
  border-radius: 100px;
  font-size: var(--text-sm);
  padding: var(--space-xxs) var(--space-std);
  transition: all ease-in-out .25s;

  &:hover,
  &:focus {
    border-color: var(--color--copy-primary);
  }

  &[aria-pressed=true] {
    background: var(--color--bg-dark);
    color: white;
  }
}
</style>
