<template>
  <span :class="classes">
    <slot />
  </span>
</template>

<script setup lang="ts">
import { BadgeColor } from '~/types/enum/component/the-badge';

const props = defineProps({
  color: {
    type: String as () => BadgeColor,
    required: false,
    default: undefined,
  },
});

const classes = ['badge'];
if (props.color) {
  classes.push(`badge--${props.color}`);
}
</script>

<style scoped lang="scss">
.badge {
  height: 1.25em;
  width: 1.25em;
  font-size: var(--text-xxs);
  background: var(--color--border);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: var(--color--copy-primary);

  &--red {
    background: var(--color--brand-primary);
    color: white;
  }
}
</style>
