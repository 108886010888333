<template>
  <MetaHead />

  <main>
    <TheMarquee />
    <TheHeader />
    <div class="main">
      <PageContainer>
        <router-view />
      </PageContainer>
    </div>
    <TheFooter />
  </main>
</template>

<style scoped lang="scss">
.main {
  margin-top: var(--space-sm);
}
</style>
