<template>
  <div class="page-based-container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.page-based-container {
  width: var(--width--page-based-container);
  padding: 0 var(--space-sm);
  max-width: 100%;
  margin: 0 auto;
  container: page-based-container / inline-size;

  @media (min-width: 575px) {
    padding: 0 var(--space-std);
  }
  @media (min-width: 800px) {
    padding: 0 var(--space-lg);
  }
}
</style>
