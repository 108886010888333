import {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import { Either, left, right } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { AbsoluteUrlString, UrlString } from '~/types/types';
import { useApiBaseUrlStore } from '~/store/api-base-url';

export interface RestRequestPayload {
  [p: string]: any;
}

export interface JsonApiRequestPayload {
  data: {};
}

export enum AxiosOperations {
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete',
  Get = 'get'
}

/**
 * Represents the contents of a potential axios request.
 *
 * Since it does not contain a data property, an object implementing
 * this interface is likely a GET or DELETE request.
 */
export interface AxiosInteractionBase {
  axiosInstance: AxiosInstance,
  url: UrlString,
  config: AxiosRequestConfig,
}

/**
 * Represents the contents of a potential axios request that required a data payload.
 *
 * This is likely to be a POST or PATCH request.
 */
export interface AxiosInteractionWithData extends AxiosInteractionBase {
  data: RestRequestPayload | JsonApiRequestPayload;
}

/**
 * An interaction that may or may not contain a data payload.
 *
 * This is implemented in the case where multiple types of Interactions
 * are being used in the same place, so the data may or may not be present.
 */
// @ts-ignore
interface AxiosInteractionMaybeData extends AxiosInteractionBase {
  data?: RestRequestPayload | JsonApiRequestPayload;
}

/**
 * Specifies that a given promise represents the data property of an AxiosResponse.
 */
export type AxiosResponseData<T> = T;

export type ApiError = AxiosError | Error;

export type Errorable<T> = Either<ApiError, T>

/**
 * Provides an FP approach to bubbling an axios try/catch as data.
 */
export type EitherAxiosResponseData<T> = Errorable<AxiosResponseData<T>>

/**
 * Execute an axios callback and then return its data.
 */
const thenReturnData = async <T>(axiosCallback: Promise<AxiosResponse>): Promise<EitherAxiosResponseData<T>> => {
  try {
    const response: AxiosResponse = await axiosCallback;
    // console.log('response is: ', response);
    return right(response.data);
  } catch (e: any | AxiosError | Error) {
    return left(e);
  }
};

/**
 * Make the request for the given operation on the given interaction.
 *
 * @param interaction
 * @param operation
 */
const executeInteraction = <T>(interaction: AxiosInteractionBase | AxiosInteractionWithData, operation: AxiosOperations): Promise<EitherAxiosResponseData<T>> => {
  const {
    axiosInstance,
    url,
    config,
  } = interaction;

  interface RequestMetadata {
    url: UrlString,
    data?: RestRequestPayload | JsonApiRequestPayload,
    config: AxiosRequestConfig
  }

  if ('data' in interaction && typeof interaction.data === 'undefined') {
    console.warn('Data prop on the provided interaction is undefined. This should probably be set to something if it exists.');
  }

  const requestMetadata: RequestMetadata = {
    url,
    data: 'data' in interaction
      ? interaction.data
      : undefined,
    config,
  };

  const makeRequest = (axiosInstance: AxiosInstance, operation: AxiosOperations, requestMetadata: RequestMetadata) => (typeof requestMetadata.data !== 'undefined'
    ? axiosInstance[operation](requestMetadata.url, requestMetadata.data, requestMetadata.config)
    : axiosInstance[operation](requestMetadata.url, requestMetadata.config));

  // DO NOT modify this to be executed in the format
  // thenReturnData<T>(makeRequest(...)). This should not
  // be problematic (right...?), but for some reason causes
  // an indecipherable error in the application renderer
  // which cannot easily be traced back here.
  //
  // Also not sure why the TS parser is choking here,
  // since the previous line is passed into thenReturnData
  // as the expected param.
  return pipe(
    makeRequest(axiosInstance, operation, requestMetadata),
    thenReturnData<T>,
  );
};

const absoluteUrlIsValid = (absoluteUrl: AbsoluteUrlString) => absoluteUrl.charAt(0) === '/';

export const ApiCommon = {
  /**
   * Provides common axios methods.
   */
  axios: {
    post: <T>(interaction: AxiosInteractionWithData) => executeInteraction<T>(interaction, AxiosOperations.Post),
    patch: <T>(interaction: AxiosInteractionWithData) => executeInteraction<T>(interaction, AxiosOperations.Patch),
    get: <T>(interaction: AxiosInteractionBase) => executeInteraction<T>(interaction, AxiosOperations.Get),
    delete: <T>(interaction: AxiosInteractionBase) => executeInteraction<T>(interaction, AxiosOperations.Delete),
  },

  /**
   * URL utils.
   */
  url: {
    /**
     * Returns the base url of the active frontend.
     *
     * This should not be used in contexts that are loaded in SSR
     * because the window will not exist.
     */
    getBaseUrl: () => window.location.origin,

    completeAbsoluteUrl: (absoluteUrl: AbsoluteUrlString): UrlString => {
      if (!absoluteUrlIsValid(absoluteUrl)) {
        throw 'Provided absoluteUrl does not begin with a slash.';
      }

      return String.prototype.concat(useApiBaseUrlStore().apiBaseUrl, absoluteUrl);
    },
  },

};
