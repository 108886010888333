<template>
  <div ref="container" />
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import sanitizeHtml from 'sanitize-html';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps<{ content: string }>();
const container = ref<HTMLElement | null>(null);

/**
 * Replaces anchors from the markup with router-links.
 *
 * This is especially useful when rendering content that
 * has been fetched from the backend, since the authoring
 * system will be formatting links to other content as /foo.
 * Therefore, we know that any links starting with a slash
 * should be considered to link to somewhere in the frontend
 * and can/should be handled by the router instead.
 *
 * @param el
 *   The HTMLElement whose links should be replaced.
 */
const handleAnchorsAsRouterLinks = (el: HTMLElement) => {
  const frontendAnchors = el.querySelectorAll('a[href^="/"]:not([href^="#"])');
  frontendAnchors.forEach((link) => {
    const href = link.getAttribute('href') || '';

    // Add a new click event listener to navigate using the router
    link.addEventListener('click', (event) => {
      event.preventDefault();
      router.push(href);
    });
  });
};

// This will run on initial mount and on changes.
watchEffect(() => {
  if (container.value) {
    // We need to handle the sanitize and replace in two steps,
    // because sanitizing requires a string but the anchor to
    // router-link conversion requires an actual HTMLElement.
    // So this first sets the sanitized html as the markup and
    // then handles the anchor replacement.
    container.value.innerHTML = sanitizeHtml(props.content, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img']),
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        h1: ['id'],
        h2: ['id'],
        h3: ['id'],
        h4: ['id'],
        h5: ['id'],
        h6: ['id'],
        img: ['src', 'alt'],
      },
    });
    handleAnchorsAsRouterLinks(container.value);
  }
});

defineExpose({ markupElement: container });
</script>
