import { pipe } from 'fp-ts/function';
import { Logger } from '~/types/types';
import { Errorable } from '~/composables/api/api-common';

export interface LoadingState {
  isLoading: () => boolean,
  /** @deprecated Direct usage of start() is deprecated. Please use addTask() where possible. */
  start: () => void,
  /** @deprecated Direct usage of end() is deprecated. Please use removeTask() where possible. */
  end: () => void,
  taskCount: () => number,
  addTask: () => number,
  removeTask: () => number,

  /**
   * Adds a task to the state while a callback is running, then removes it.
   *
   * Ideally we could have standalone, reusable types for LoadWhile and
   * ErrorableLoadWhile, but it appears that there is not a way for us
   * to do this while still being able to pass T when calling them. Adding
   * the types necessitates adding LoadingState<T> or using LoadWhile<any>,
   * both of which are insufficient.
   *
   * @param f
   * @param logger
   */
  loadWhile: <T>(f: () => Promise<T>, logger?: Logger) => Promise<T>,
  errorableLoadWhile: <T>(f: () => Promise<Errorable<T>>, logger?: Logger) => Promise<Errorable<T>>,
}

/**
 * Provides a handler for loading states.
 */
export const useLoadingState = (): LoadingState => {
  const isLoading = ref<boolean>(false);
  const forceLoading = ref(false);

  const taskCounter = ref(0);
  const taskCount = () => taskCounter.value;

  const addTask = () => {
    isLoading.value = true;
    taskCounter.value++;
    return taskCounter.value;
  };
  const removeTask = () => {
    taskCounter.value--;
    if (taskCount() < 0) {
      taskCounter.value = 0;
    }

    if (taskCount() === 0) {
      isLoading.value = false;
    }

    return taskCounter.value;
  };

  const loadWhile = async <T>(f: () => Promise<T>, logger: Logger | null = null): Promise<T> => {
    addTask();
    const result = await pipe(
      f(),
      (x) => (logger === null
        ? x
        : logger(x)),
    );
    removeTask();
    return result;
  };

  return {
    taskCount,
    addTask,
    removeTask,

    isLoading: (): boolean => isLoading.value || forceLoading.value,

    loadWhile,

    errorableLoadWhile: async <T>(f: () => Promise<Errorable<T>>, logger: Logger | null = null): Promise<Errorable<T>> => loadWhile<Errorable<T>>(f, logger),

    start: () => {
      // console.warn('Direct usage of start() is deprecated. Please use errorableLoadWhile() instead.');
      forceLoading.value = true;
    },

    end: () => {
      // console.warn('Direct usage of end() is deprecated. Please use errorableLoadWhile() instead.');
      forceLoading.value = false;
    },

  };
};
