import type { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import { AxiosRequestConfig } from 'axios';
import {
  Either, isLeft, left, match, right,
} from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { axiosJsonApi } from '~/composables/axios-json-api';
import type { SwrvSettings } from '~/composables/swrv-wrapper';
import { swrvr } from '~/composables/swrv-wrapper';
import { useApiBaseUrlStore } from '~/store/api-base-url';
import { ApiCommon } from '~/composables/api/api-common';
import { notEmpty } from '~/common/empty';
import { FileDataInclude } from '~/types/json-api/file';
import { ProductData, ProductDataBasic } from '~/types/json-api/commerce';
import {
  JsonResponseIncluded,
  RelationshipArrMaybeData,
  RelationshipSingle,
  ResponseDataCommon,
} from '~/types/json-api/json-api';
import { ImageStyle } from '~/types/enum/enum';

/**
 * Components required to construct a json api request url.
 *
 * Notes:
 *   bundle     - For commerce api cart interactions, this might be 'add'.
 *                Maybe create a second interface & interaction methods
 *                specifically for commerce so that it's not confusing.
 *
 *   isCommerce - The commerce_api module modifies the way that resources
 *                are constructed. Specifically, it pluralizes the names of
 *                all commerce Entity Types in the url, so that instead of
 *                /currency, you'd request /currencies. Setting isCommerce
 *                to true allows us to pass in the singular entity name
 *                just like we would for every other resource.
 *
 *   trailing   - Sometimes resources may need extra subdirectories appended
 *                to the end of the request. This doesn't seem to generally
 *                be needed, really, but it's at least required for modifying
 *                cart items per https://www.drupal.org/docs/8/modules/commerce-api/cart-and-checkout/modifying-cart-items
 */
export interface RequestUrlComponents {
  entityType: string,
  bundle?: string,
  uuid?: string,
  params?: DrupalJsonApiParams,
  isCommerce?: boolean,
  trailing?: string | string[]
}

export interface PatchPayload {
  data: {
    type: string,
    id: string,
    attributes: {},
    relationships?: {}
  };
}

// @see https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/updating-existing-resources-patch#s-basic-patch-request
interface RequestData {
  data: {
    type: string,
    id?: string,
    attributes: Object,
    relationships?: Object
  };
}

/**
 * Pluralizes a string for use in commerce resource requests.
 *
 * @see https://www.drupal.org/docs/8/modules/commerce-api/about-the-api
 * @param str - The entity type.
 */
const commercePluralize = (str: string) => (str === 'currency'
  ? 'currencies'
  : `${str}s`);

const getJsonApiUrlBase = () => {
  const apiBaseUrlStore = useApiBaseUrlStore();
  return `${apiBaseUrlStore.apiBaseUrl}/jsonapi`;
};

/**
 * Build a request url from it's components.
 *
 * @param options - The request url components.
 */
const buildRequestUrl = (options: RequestUrlComponents) => {
  const entityType = ('isCommerce' in options && options.isCommerce)
    ? commercePluralize(options.entityType)
    : options.entityType;

  // console.log('API base url is: ', apiBaseUrlStore.apiBaseUrl);

  const jsonApiUrlBase = getJsonApiUrlBase();
  let url = `${jsonApiUrlBase}/${entityType}`;
  if ('bundle' in options && options.bundle) {
    url += `/${options.bundle}`;
  }
  if ('uuid' in options && options.uuid) {
    url += `/${options.uuid}`;
  }
  if ('trailing' in options && options.trailing) {
    const ext = typeof options.trailing === 'object' // i.e. array
      ? options.trailing.join('/')
      : options.trailing;
    url += `/${ext}`;
  }
  if ('params' in options && options.params) {
    const q = options.params.getQueryString();
    url += `?${q}`;
  }

  // console.log('request url is: ', url);

  return url;
};

/**
 * Get the Image Urls for an entity given its response data and a field name.
 *
 * @param response - The response holding the included item.
 * @param entityData - The entity data. This needs to be passed explicitly because
 *  there may be multiple entities in the response itself, so it cannot be inferred.
 * @param fieldName - The name of the image field.
 * @param imageStyle - The name of the image style.
 * @param convertToWebp - Whether to replace the extension with webp.
 */
const parseImageUrls = (response: JsonResponseIncluded<any>, entityData: ResponseDataCommon, fieldName: string, imageStyle: ImageStyle, convertToWebp: boolean = true) => {
  if (!entityData.relationships[fieldName]) {
    throw `Field name ${fieldName} does not exist on the given entity relationships.`;
  }

  const fieldRelationship: RelationshipSingle | RelationshipArrMaybeData = entityData.relationships[fieldName];

  if (!('data' in fieldRelationship) || !fieldRelationship.data) {
    console.warn('Data prop is missing on the given relationship: ', fieldRelationship);
    return [];
  }

  const imageUuids = 'id' in fieldRelationship.data
    ? [fieldRelationship.data.id]
    : fieldRelationship.data.map((x) => x.id);

  // console.log(imageUuids);
  const imagesData: FileDataInclude[] = imageUuids.map((id: string) => pipe(
    parseIncluded<FileDataInclude>(response, id),
    match(
      () => null,
      (x) => x,
    ),
  ))
    .filter(notEmpty);

  const urls = imagesData.map((x) => {
    if (!x) {
      throw 'The given file does not exist in the response "includes". Make sure that you include the required field in your api params.';
    }
    const styleName = imageStyle;
    const styles = x.attributes.image_style_uri;

    return styles.hasOwnProperty(styleName)
      ? styles[styleName]
      : undefined;
  });

  return convertToWebp
    ? urls.map((url) => (typeof url !== 'undefined'
      ? url.replace(/\.\w+?(\?|$)/, '.webp$1')
      : undefined))
    : urls;
};

/**
 * Get the first available Image Url for an entity given its response data and a field name.
 *
 * @param response - The response holding the included item.
 * @param entityData - The entity data. This needs to be passed explicitly because
 *  there may be multiple entities in the response itself, so it cannot be inferred.
 * @param fieldName - The name of the image field.
 * @param imageStyle - The name of the image style.
 * @param convertToWebp - Whether to replace the extension with webp.
 */
const parseImageUrlSingle = (response: JsonResponseIncluded<any>, entityData: ResponseDataCommon, fieldName: string, imageStyle: ImageStyle, convertToWebp: boolean = true) => parseImageUrls(response, entityData, fieldName, imageStyle, convertToWebp)[0];

/**
 * Get an included item from a json response.
 *
 * @param response - The response holding the included item.
 * @param id - The uuid of the included item.
 */
const parseIncluded = <T>(response: JsonResponseIncluded<any>, id: string): Either<undefined, T> => {
  if (!response.hasOwnProperty('included')) {
    // @todo: Need to handle this so that it isn't an error..
    throw 'Response is missing an "included" property. Did you forget to pass a DrupalJsonApiParams() into the request? This also might be the result of insufficient permissions. Verify permissions on all requested entity types.';
  }

  const included = response.included.find((include) => id === include.id);
  return !included
    ? left(included)
    : right(included);
};

/**
 * Gets the UUID from a relationship.
 *
 * @param relationshipParent
 *   A response object containing a "relationships" property.
 * @param relationshipKey
 *   The relationship whose IDs you'd like to grab.
 */
const parseRelationshipUuids = (relationshipParent: ResponseDataCommon, relationshipKey: string): Either<Error, string[]> => {
  if (!relationshipParent.relationships[relationshipKey]) {
    return left(new Error('The provided relationship key does not exist on the given parent.'));
  }

  const r = relationshipParent.relationships[relationshipKey];

  // The data property will not always exist and shouldn't ever actually
  // end up being undefined, so just return an empty array as an indication
  // that there is no related data.
  if (!r.data) {
    return right([]);
  }

  if (!Array.isArray(r.data)) {
    return right([r.data.id]);
  }

  // Filter out "missing" elements to address entity reference fields.
  // This happens when a referenced entity is deleted but the reference
  // itself remains.
  return right(r.data.map((x) => x.id)
    .filter((x) => x !== 'missing'));
};

/**
 * Get all the included objects for a given relationship.
 *
 * @param response
 *   The initial response containing all data and included data.
 * @param relationshipParent
 *   A response's child object containing a "relationships" property.
 * @param relationshipKey
 *   The relationship whose included objects you'd like to grab.
 * @param removeDuplicates
 *   If any UUIDs appear more than once, this will ensure they exist only ones.
 */
const parseRelationshipObjects = <T>(response: JsonResponseIncluded<any>, relationshipParent: ResponseDataCommon, relationshipKey: string, removeDuplicates: boolean = true): Either<Error, T[]> => {
  const uuids = parseRelationshipUuids(relationshipParent, relationshipKey);
  if (isLeft(uuids)) {
    return left(uuids.left);
  }

  const uuidsFinal = removeDuplicates
    ? [...new Set(uuids.right)]
    : uuids.right;

  const related = uuidsFinal.map((uuid: string) => pipe(
    parseIncluded<T>(response, uuid),
    match(() => null, (x) => x),
  ))
    .filter(notEmpty);
  return right(related);
};

export const useDrupalJsonApi = () => ({
  getJsonApiUrlBase,

  /**
   * In an ideal world, the above axios operations would be replaced with
   * the ones below in order to leverage all of the common api logic.
   * For some reason, though, it seems that the common implementation
   * breaks the integration for unknown reasons. Until we can figure out
   * why that's happening, we need to use the original implementations.
   */
  /**
   * Make a POST request.
   *
   * @param options  - The request options.
   * @param data     - The data to POST.
   * @param config   - Additional config to pass into axios.
   */
  post: <T>(options: RequestUrlComponents, data: RequestData, config: AxiosRequestConfig = {}) => ApiCommon.axios.post<JsonResponseIncluded<T>>({
    axiosInstance: axiosJsonApi,
    url: buildRequestUrl(options),
    data,
    config,
  }),

  /**
   * Make a PATCH request.
   *
   * @param options  - The request options.
   * @param data     - The data to POST.
   * @param config   - Additional config to pass into axios.
   */
  patch: <T>(options: RequestUrlComponents, data: RequestData, config: AxiosRequestConfig = {}) => ApiCommon.axios.patch<T>({
    axiosInstance: axiosJsonApi,
    url: buildRequestUrl(options),
    data,
    config,
  }),

  /**
   * Make a GET request.
   *
   * @param options  - The request options.
   * @param config   - Additional config to pass into axios.
   */
  get: <T>(options: RequestUrlComponents, config: AxiosRequestConfig = {}) => ApiCommon.axios.get<JsonResponseIncluded<T>>({
    axiosInstance: axiosJsonApi,
    url: buildRequestUrl(options),
    config,
  }),

  /**
   * Make a DELETE request.
   *
   * Per https://stackoverflow.com/questions/51069552/axios-delete-request-with-body-and-headers,
   * delete requests shouldn't technically have a body. If a payload is needed
   * then it must be passed on the config.data object. This may result in a
   * confusing case where you have config.data.data.{payload}.
   *
   * @param options  - The request options.
   * @param config   - Additional config to pass into axios. Data needs to be pass on the data prop.
   */
  delete: <T>(options: RequestUrlComponents, config: AxiosRequestConfig = {}) => ApiCommon.axios.delete<T>({
    axiosInstance: axiosJsonApi,
    url: buildRequestUrl(options),
    config,
  }),

  /**
   * Make a GET request with SWRV.
   *
   * @param options       - The request options.
   * @param settings      - The settings to be passed into SWRV. Provides the ability
   *                        to pass additional "config" into the axios request.
   * @param instantReload - Whether to reload the swrvr right away.
   *
   * @todo Need to figure out how to make this compatible with the Either pattern.
   */
  getSwrv: (options: RequestUrlComponents, settings?: SwrvSettings, instantReload: boolean = true) => {
    const url = buildRequestUrl(options);
    // console.log({options, url, settings})
    const swrv = swrvr(url, settings);

    // This was added because sometimes content would fail to render/fetch
    // when navigating forward and backward in the browser. This was caused
    // by the swrvr not reliably issuing a request on each page/component
    // load. By adding an instantReload by default, we help to ensure that
    // this issue is not encountered, but still allow the implementation to
    // override the behavior as needed.
    if (instantReload) {
      swrv.reload();
    }

    return swrv;
  },

  parseIncluded,
  parseRelationshipUuids,
  parseRelationshipObjects,
  parseImageUrls,
  parseImageUrlSingle,

  /**
   * Get the Image Urls for a product given its response data.
   *
   * @param response - The response holding the included item.
   * @param product - The product data.
   * @param imageStyle - The name of the image style.
   * @param convertToWebp - Whether to replace the extension with webp.
   */
  parseProductImageUrls: (response: JsonResponseIncluded<any>, product: ProductDataBasic | ProductData, imageStyle: ImageStyle, convertToWebp: boolean = true) => parseImageUrls(response, product, 'field_product_images', imageStyle, convertToWebp),
});
