// import { createFloatingLabelsPlugin } from '@formkit/addons';
// import '@formkit/addons/css/floatingLabels';
import { DefaultConfigOptions } from '@formkit/vue';

//
// const isCheckboxAndRadioMultiple = (node) => (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options;
//
// function addAsteriskPlugin(node) {
//   node.on('created', () => {
//     const schemaFn = node.props.definition.schema;
//     node.props.definition.schema = (sectionsSchema = {}) => {
//       const isRequired = node.props.parsedRules.some((rule) => rule.name === 'required');
//
//       if (isRequired) {
//         if (isCheckboxAndRadioMultiple(node)) {
//           sectionsSchema.legend = {
//             children: ['$label', '*'],
//           };
//         } else {
//           sectionsSchema.label = {
//             children: ['$label', '*'],
//           };
//         }
//       }
//       return schemaFn(sectionsSchema);
//     };
//   });
// }

const config: DefaultConfigOptions = {
  plugins: [
    // addAsteriskPlugin,
    // createFloatingLabelsPlugin({
    //   useAsDefault: true, // defaults to false
    // }),
  ],
};

export default config;
