import { Ref } from 'vue-demi';

export interface ModalState {
  isOpen: Ref<boolean>,
  onClose: () => Promise<void>,
  close: () => void,
  open: () => void,
}

/**
 * Provides a simple state handler for modals.
 */
export const useModalState = (): ModalState => {
  const isOpen = ref<boolean>(false);
  const onClose = (): Promise<void> => new Promise((resolve) => {
    if (isOpen.value === false) {
      return resolve();
    }
    setTimeout(() => onClose(), 100);
  });

  return {
    isOpen,

    onClose,

    close: (): void => {
      isOpen.value = false;
    },

    open: (): void => {
      isOpen.value = true;
    },
  };
};
