<template>
  <div
    class="dynamic-form-container"
    :class="{pad: pad, border: border}"
  >
    <div class="heading">
      <slot name="heading" />
    </div>
    <div
      v-if="errors.length > 0"
      :id="errorsId"
    >
      <ul>
        <li v-for="error in errors">
          {{ error }}
        </li>
      </ul>
    </div>
    <slot
      name="form"
      @submit.prevent="submitForm"
    />
    <div
      :id="`captcha-${formId}`"
      class="captcha"
    >
      <slot name="captcha" />
    </div>
    <div
      :id="`actions-${formId}`"
      class="actions"
    >
      <slot name="actions" />
    </div>
    <div class="footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
// Wrapper component for '@asigloo/vue-dynamic-forms';
// This should remain a "dumb" component and mostly
// exists to provide a consistent wrapper div and types
// until the module provides some ts-config.

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  pad: {
    type: Boolean,
    required: false,
    default: false,
  },
  border: {
    type: Boolean,
    required: false,
    default: false,
  },
  errors: {
    type: Array as () => string[],
    required: false,
    default: [],
  },
});

const errorsId = `${props.formId}-errors`;
const errors = ref<string[]>(props.errors);

watch(errors, () => {
  if (errors.value.length > 0) {
    const errorsEl = document.getElementById(errorsId);
    if (errorsEl) {
      errorsEl.focus();
    }
  }
});

export interface SelectOption {
  value: string,
  label: string
}

export interface ValidatorSchema {
  validator: any, // such as required, as in // import { required } from '@asigloo/vue-dynamic-forms';
  text: string
}

export interface FieldSchema {
  label: string,
  validations?: (arg0: ValidatorSchema) => string
  customClass?: string
}

export interface SelectFieldSchema extends FieldSchema {
  options: SelectOption[]
}

export type RadioFieldSchema = SelectFieldSchema;

export interface NumberFieldSchema extends FieldSchema {
  value: number,
}

export interface BoolFieldSchema extends FieldSchema {
  value: boolean,
}

export interface TextAreaSchema extends FieldSchema {
  col?: number,
  rows?: number
}

type MixedFieldSchema =
  FieldSchema
  | SelectFieldSchema
  | NumberFieldSchema
  | BoolFieldSchema
  | RadioFieldSchema
  | TextAreaSchema;

export interface FormSchema {
  id: string,
  fields: {
    [index: string]: MixedFieldSchema
  }
}

onMounted(() => {
  const actionsEl = document.getElementById(`actions-${props.formId}`);
  const formEl = document.getElementById(props.formId);
  const captchaEl = document.getElementById(`captcha-${props.formId}`);

  if (formEl) {
    if (captchaEl) {
      formEl.appendChild(captchaEl);
    }

    if (actionsEl) {
      formEl.appendChild(actionsEl);
    }
  }
});

</script>

<style scoped>
:deep(form) {
  width: 100%
}

/*:deep(.hidden) {*/
/*  position: absolute;*/
/*  left: -10000px;*/
/*}*/

.dynamic-form-container {
  /*margin: 4em auto;*/
  /*width: 400px;*/
  display: flex;
  flex-flow: column;
  /** To prevent stretching by default */
  align-items: flex-start;
}

.pad {
  padding: 1.5em 2em;
}

.border {
  border: solid 1px var(--color--border);
  border-radius: var(--border-radius--standard);
}

.heading :deep(*) {
  margin-top: 0;
}
</style>
