import { Channel, StreamChat } from 'stream-chat';
import { pipe } from 'fp-ts/function';
import { callWithAuthHeader, useAuthStore } from '~/store/auth';
import { useDrupalRestApi } from '~/composables/api/drupal-rest-api';
import { EnumType } from '~/types/enum/enum';
import { createConstEnum } from '~/common/enum';
import { GenericObject } from '~/types/types';
import { ChatMessageEntityType, UserConnect } from '~/types/stream-chat';

export const ChatMessageOwner = createConstEnum({
  ACTIVE_USER: 'active-user',
  OTHER_USER: 'other-user',
  BOT: 'bot',
});

export type ChatMessageOwner = EnumType<typeof ChatMessageOwner>

export interface StoreMessageResponseData {
  message_id: string,
  channel_id: string,
}

export interface FetchTokenResponseData {
  // The stream chat user id
  id: string,
  name: string,
  token: string
}

export interface FlaggedMessagesData {
  flagged_message_ids: string[];
}

export interface ChannelQueryParams {
  filter?: GenericObject,
  entityType?: ChatMessageEntityType,
  sort?: GenericObject,
  options?: GenericObject,
}

export const useStreamChatApi = () => {
  const authStore = useAuthStore();
  const rest = useDrupalRestApi();

  const getChannels = async (client: StreamChat, params: ChannelQueryParams = {}) => {
    const baseFilters: GenericObject = {
      type: 'messaging',
    };

    if (client.user?.id) {
      baseFilters.members = { $in: [client.user.id] };
    }

    const finalFilters = {
      ...baseFilters,
      ...params.filter,
    };

    // Important to check whether the property exists and not
    // whether it is set, because null is a valid value.
    if ('entityType' in params) {
      finalFilters.entity_type = params.entityType;
    }

    const finalSort: GenericObject = {
      last_message_at: -1,
      ...params.sort,
    };

    const finalOptions = {
      state: true,
      ...params.options,
    };

    return client.queryChannels(finalFilters, finalSort, finalOptions);
  };

  return {
    formatDate: (messageDate: string): string => {
      const date = new Date(messageDate);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };

      return date.toLocaleDateString('en-US', options);
    },
    getPublicKey: async () => callWithAuthHeader(
      authStore,
      (authHeader) => rest.get<string>(
        '/api/stream-chat/public-key',
        authHeader,
      ),
    ),
    getUserMetadata: async () => callWithAuthHeader(
      authStore,
      (authHeader) => rest.get<FetchTokenResponseData>(
        '/api/stream-chat/token',
        authHeader,
      ),
    ),
    getFlaggedMessages: async (cid: string) => callWithAuthHeader(
      authStore,
      (authHeader) => rest.post<FlaggedMessagesData>(
        '/api/stream-chat/flagged-messages',
        { cid },
        authHeader,
      ),
    ),
    sendMessageToStore: (message: string, storeId: string, token: string) => callWithAuthHeader(
      authStore,
      (authHeader) => rest.post<StoreMessageResponseData>(
        '/api/stream-chat/message-store',
        {
          token,
          store: storeId,
          message,
        },
        authHeader,
      ),
    ),
    getChannels,
    // @ts-ignore
    connectUser: async (client: StreamChat, userId: string, token: string): Promise<UserConnect> => client.connectUser({
      id: userId,
    }, token),
    getUnreadCountByEntityType: async (client: StreamChat, et: ChatMessageEntityType) => pipe(
      await getChannels(client, {
        entityType: et,
        options: {
          state: true,
          watch: false,
          presence: false,
        },
      }),
    )
      .reduce((total: number, channel: Channel) => total + channel.countUnread(), 0),
  };
};
