<template>
  <DropdownMenuBase
    ref="dropdown"
    label="account menu"
    :is-image-button="true"
  >
    <template #img>
      <TheItem>
        <clarity-user-solid class="user" />

        <template
          v-if="unreadMessagesCount > 0"
          #suffix
        >
          <TheBadge :color="BadgeColor.RED">
            {{ unreadMessagesCount }}
          </TheBadge>
        </template>
      </TheItem>
    </template>
    <slot>
      <MenuItem v-if="apiBaseUrl">
        <a
          :href="apiBaseUrl"
          target="dashboard"
        >
          <TheItem>
            <template #prefix>
              <Icon
                icon="ph:sliders"
                class="icon icon--dashboard"
              />
            </template>
            Dashboard
          </TheItem>
        </a>
      </MenuItem>
      <MenuItem>
        <router-link :to="{ name: 'messages'}">
          <TheItem>
            <template #prefix>
              <Icon
                icon="ph:chat-dots"
                class="icon icon--chat"
              />
            </template>
            Messages
            <template
              v-if="unreadMessagesCount > 0"
              #suffix
            >
              <TheBadge>
                {{ unreadMessagesCount }}
              </TheBadge>
            </template>
          </TheItem>
        </router-link>
      </MenuItem>
      <MenuItem>
        <router-link to="/logout">
          <TheItem>
            <template #prefix>
              <Icon
                icon="ph:sign-out"
                class="icon icon--logout"
              />
            </template>
            Log Out
          </TheItem>
        </router-link>
      </MenuItem>
    </slot>
  </DropdownMenuBase>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Icon } from '@iconify/vue';
import { MenuItem } from '@headlessui/vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/store/auth';
import { useApiBaseUrlStore } from '~/store/api-base-url';
import { useStreamChatStore } from '~/store/stream-chat';
import DropdownMenuBase from '~/components/ui-misc/DropdownMenuBase.vue';
import { BadgeColor } from '~/types/enum/component/the-badge';

const authStore = useAuthStore();
const apiBaseUrlStore = useApiBaseUrlStore();
const chatStore = useStreamChatStore();
const router = useRouter();

const { unreadMessagesCount } = storeToRefs(chatStore);
const { apiBaseUrl } = storeToRefs(apiBaseUrlStore);

const isLoggedIn = computed(() => authStore && authStore.isAuthenticated);
</script>

<style lang="scss" scoped>
a {
  font-size: var(--text-sm);
}

:deep([role=menu]) {
  right: 0;
  left: unset;
}

</style>
