import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { UrlString } from '~/types/types';
import { trimChar } from '~/common/string';
import { ProductMetadata } from '~/composables/api/commerce/product';

interface ProductPrecacheState {
  data: Map<string, ProductMetadata>;
}

/**
 * Provides a store for precaching product data.
 *
 * This allows us to optimistically show product information
 * immediately on page load while fresh data is fetched from
 * the server. So, when a user visits a listing page and then
 * clicks on a product, all of the information shows instantly,
 * while the page's own handling can fetch more specific data
 * as needed and replace it once the response is received.
 *
 * This somewhat duplicates how our general data store is
 * used for caching responses by query string, but its
 * more specific.
 */
export const useProductPrecacheStore = () => {
  const route = useRoute();

  return (defineStore({
    id: 'productPrecache',
    state: (): ProductPrecacheState => ({
      data: new Map(),
    }),
    actions: {
      set(path: UrlString, data: ProductMetadata) {
        this.data.set(trimChar(path, '/'), data);
      },
      get(path: UrlString) {
        return this.data.get(trimChar(path, '/'));
      },
      getForCurrentPath() {
        return this.get(trimChar(route.path, '/'));
      },
    },
  }))();
};
