import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import { useDrupalRestApi } from '~/composables/api/drupal-rest-api';
import { AuthorizationHeader, callWithAuthHeader, useAuthStore } from '~/store/auth';
import { EitherAxiosResponseData } from '~/composables/api/api-common';
import { JsonResponse } from '~/types/json-api/json-api';
import { DrupalRole } from '~/types/drupal-common';

export const useUserApi = () => {
  const drupalRestApi = useDrupalRestApi();
  const authStore = useAuthStore();

  return {
    getCurrentUserId: async (): Promise<EitherAxiosResponseData<string>> => pipe(
      await callWithAuthHeader(
        authStore,
        async (h) => drupalRestApi.get<Promise<JsonResponse<unknown>>>('/jsonapi', h),
      ),
      E.map((response) => response.meta.links.me.meta.id),
    ),
    addRoles: async (roles: string[], authHeader: AuthorizationHeader): Promise<EitherAxiosResponseData<any>> => await drupalRestApi.post('/api/assign-roles', { roles }, authHeader),
    getRoles: async (authHeader: AuthorizationHeader): Promise<EitherAxiosResponseData<DrupalRole[]>> => await drupalRestApi.get('/api/get-roles', authHeader),
  };
};
