<template />

<script setup lang="ts">
onMounted(() => {
  // Cribbed from https://community.hubspot.com/t5/APIs-Integrations/Using-Hubspot-tracking-script-with-Google-Tag-Manager-and-a-Vue/m-p/518550
  const _hsq = window._hsq = window._hsq || [];

  // Entry page on website
  _hsq.push(['setPath', window.location.pathname + window.location.search]);

  // Subsequent page views / router changes
  function trackPageView() {
    _hsq.push(['setPath', window.location.pathname + window.location.search]);
    _hsq.push(['trackPageView']);
  }

  (function () {
    const { pushState } = window.history;
    const { replaceState } = window.history;

    window.history.pushState = function () {
      pushState.apply(window.history, arguments);
      trackPageView();
    };

    window.history.replaceState = function () {
      replaceState.apply(window.history, arguments);
      trackPageView();
    };

    window.addEventListener('popstate', () => {
      trackPageView();
    });
  }());

  // Step 1
  const script = document.createElement('script');
  // Step 2
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = '//js-na1.hs-scripts.com/22266328.js';
  script.id = 'hs-script-loader';
  // Step 3
  document.body.appendChild(script);
});

</script>
