<template>
  <Menu
    class="dropdown-menu"
    as="div"
  >
    <MenuButtonChevron :data-hc-uuid="uuid">
      <template v-if="isImageButton">
        <div class="imageButtonLabel">
          {{ label }}
        </div>
        <div class="imageWrapper">
          <slot name="img" />
        </div>
      </template>
      <template v-else>
        {{ label }}
      </template>
    </MenuButtonChevron>

    <MenuItems>
      <slot>
        <!--      Pass the links <MenuItem> elements into here.-->
      </slot>
    </MenuItems>
  </Menu>
</template>

<script lang="ts" setup>

import { Menu, MenuItems } from '@headlessui/vue';
import { v4 as generateUuid } from 'uuid';
import { useMutationObserver } from '@vueuse/core';
import MenuButtonChevron from '~/components/menu/MenuButtonChevron.vue';
// import logo from "~/assets/default-portrait.png";

const props = defineProps({
  isImageButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
});

const uuid = generateUuid();

/**
 * Toggles a class on active descendents.
 *
 * Headless-ui is supposed to do something similar to this by default,
 * but the associated data attribute does not seem to get toggled for
 * MenuItem children, only the other Menu elements.
 */
const handleActiveDescendentClass = () => {
  const trigger = document.querySelector(`[data-hc-uuid="${uuid}"]`);

  // Define a function to toggle a class on the target element
  const toggleClass = (newActiveDescendantId: string) => {
    // Get the current active descendant
    const currentActiveDescendant = trigger.getAttribute('data-active-descendant');
    const activeClass = 'focus';

    if (newActiveDescendantId === currentActiveDescendant) {
      return;
    }

    if (currentActiveDescendant) {
      const currentElement = document.getElementById(currentActiveDescendant);
      if (currentElement) {
        currentElement.classList.remove(activeClass);
      }
    }

    const newElement = document.getElementById(newActiveDescendantId);
    if (newElement) {
      newElement.classList.add(activeClass);
    }

    // Store the new active descendant on the target element
    trigger.setAttribute('data-active-descendant', newActiveDescendantId);
  };

  // Define a function to check if the controlled element exists
  const initObserver = () => {
    const controlledId = trigger.getAttribute('aria-controls');
    const controlledElement = document.getElementById(controlledId);
    if (!controlledElement) {
      // If the controlled element doesn't exist, set up the observer again when the target element is expanded
      trigger.addEventListener('click', initObserver);
      trigger.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          initObserver();
        }
      });
      return;
    }

    const activeDescendant = controlledElement.getAttribute('aria-activedescendant');
    if (activeDescendant) {
      toggleClass(activeDescendant);
    }

    useMutationObserver(
      controlledElement,
      (mutations) => {
        mutations.forEach((mutation) => {
          // Check if the mutation was a change to the aria-activedescendant attribute
          if (mutation.type === 'attributes'
            && mutation.attributeName === 'aria-activedescendant') {
            // Get the new value of the aria-activedescendant attribute
            const newActiveDescendant = mutation.target.getAttribute('aria-activedescendant');
            // Toggle the class on the target element
            toggleClass(newActiveDescendant);
          }
        });
      },
      {
        attributes: true,
        attributeFilter: ['aria-activedescendant'],
      },
    );
  };

  // Check if the controlled element exists when the page loads
  initObserver();
};

onMounted(() => {
  handleActiveDescendentClass();
});
</script>

<style lang="scss" scoped>
.dropdown-menu {
  position: relative;
}

.trigger {
  color: white;

  &[aria-expanded=true] {
    padding-top: var(--space-xxs);
    margin-top: calc(-1 * var(--space-xxs));
    border-top: solid 1px white;
  }
}

.imageButtonLabel {
  position: absolute;
  left: -100000px;
}

[role=menu] {
  z-index: 1;
  display: block;
  position: absolute;
  left: 0;
  transform: translateY(-1px);
  border: solid 1px var(--color--border);
  margin-top: var(--space-xs);
  background: white;
  border-radius: var(--border-radius--standard);
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  :deep(ul),
  :deep(li) {
    margin: 0 !important;
  }

  :deep(li) {
    white-space: nowrap;
    line-height: 1;
  }

  :deep(a) {
    display: block;
    padding: var(--space-xxs) var(--space-std) !important;
    color: var(--color-text-light);
  }
}

:deep([role=menuitem]) {
  white-space: nowrap;
  margin: var(--space-xs) 0;
}

//[aria-expanded=true],
[data-expanded=true] {
  display: block;
  border: solid 1px #d6d6d6;
  border-bottom-color: rgb(214, 214, 214);
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.05);
}

</style>
