<template>
  <div
    class="wrapper"
  >
    <div
      class="masthead"
      :class="{'masthead--rounded': roundedMasthead}"
    >
      <div class="masthead__inner">
        <BaseSkeleton class="masthead__image">
          <div
            v-if="slots.mastheadBottom"
            class="masthead__bottom"
          />
        </BaseSkeleton>
      </div>
    </div>

    <BaseSkeleton class="title" />
    <slot />
  </div>
</template>

<script setup lang="ts">

import { useSlots } from 'vue-demi';

const props = defineProps({
  roundedMasthead: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const slots = useSlots();

const hxSize = `h${props.hxSize}`;
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  container: card / inline-size;
}

a {
  --transition: all .2s ease-in-out;

  display: block;
  color: var(--color--copy-primary);

  &, &:hover {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    filter: revert;

    .title {
      text-decoration-color: var(--color--copy-primary);
    }

    img {
      box-shadow: var(--box-shadow);
    }
  }
}

.title {
  height: 1em;
  width: 100%;
}

.masthead {
  position: relative;
  margin-bottom: var(--space-xs);
  transition: var(--transition);

  &:hover {
    box-shadow: var(--box-shadow);
  }

  &--rounded {
    border-radius: var(--border-radius--standard);
    overflow: hidden;
  }

  &__image {
    aspect-ratio: 1/1;
    width: 100%;
    height: auto;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

}

img {
  transition: var(--transition);
  display: block;
}
</style>
