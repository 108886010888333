import { RouteLocationRaw } from 'vue-router';

export const buttonProps = {
  link: {
    type: Boolean,
    required: false,
    default: false,
  },
  to: {
    type: String as () => string | RouteLocationRaw,
    required: false,
    default: undefined,
  },
  temperature: {
    type: String,
    required: false,
    default: 'warm',
    validator: (val: string) => ['warm', 'cool', 'ghost'].includes(val),
  },
  stretch: {
    type: Boolean,
    required: false,
    default: false,
  },
  /**
   * The default state of buttons is to be "small".
   * Generally only the default and 'lg' should be
   * used, but giving the option for 'md' for flexibility.
   */
  size: {
    type: String,
    require: false,
    default: 'md',
    validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
  },
  unpadX: {
    type: Boolean,
    require: false,
    default: false,
  },
  unpadY: {
    type: Boolean,
    require: false,
    default: false,
  },
};
