/**
 * Function to create a const enum-like object from a provided object.
 * The resulting object will have the same keys and values as the input object,
 * but its properties will be readonly.
 *
 * @param obj - The object to be transformed into a const enum-like object.
 * This should be an object with string keys and either string or number values.
 *
 * @returns A frozen object with the same properties as the input object.
 * The resulting object's properties will be readonly.
 *
 * @example
 * const MyEnumObject = createConstEnum({
 *   A: 'a',
 *   B: 'b',
 *   C: 'c',
 * });
 * // MyEnumObject is { readonly A: 'a'; readonly B: 'b'; readonly C: 'c'; }
 */
export const createConstEnum = <U extends string | number | null, T extends {
  [key: string]: U
}>(obj: T): Readonly<T> => Object.freeze(obj);

/**
 * Function to create a const enum-like object from an array of keys.
 * The resulting object will have keys from the input array, and values
 * will be the indices of the array elements.
 *
 * @param keys - An array of strings to be used as keys in the resulting
 * const enum-like object.
 *
 * @returns A frozen object with keys from the input array and values
 * as array indices. The resulting object's properties will be readonly.
 *
 * @example
 * const MyEnumObject = createConstEnumByArray(['A', 'B', 'C']);
 * // MyEnumObject is { readonly A: 0; readonly B: 1; readonly C: 2; }
 */
export const createConstEnumFromArray = <T extends string[]>(keys: [...T]): Readonly<{ [K in T[number]]: number }> => {
  const obj: { [K in T[number]]: number } = {} as { [K in T[number]]: number };

  for (const [index, key] of keys.entries()) {
    obj[key] = index;
  }

  return createConstEnum(obj);
};
