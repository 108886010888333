<template>
  <div class="wrapper">
    <div class="header">
      <HeadingHx :hx="2">
        Appearances
      </HeadingHx>
      <TheLine class="line" />
    </div>

    <UlUnlisted class="appearances">
      <li class="vsa">
        <VsaLogo />
        <span class="con">Convention</span> 2022
      </li>
      <li>
        <OmoLogo class="omo-logo" />
        Podcast
      </li>
      <li class="vsa">
        <VsaLogo />
        <span class="con">Convention</span> 2024
      </li>
    </UlUnlisted>
  </div>
</template>

<script setup lang="ts">
</script>
<style scoped lang="scss">

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1em;
  margin-bottom: var(--space-std);
}

.line {
  width: 100%;

  &:before {
    background-color: white !important;
    height: 1px !important;
  }
}

.appearances {
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: .8em;
  width: 50em;
  margin: 0 auto;
  max-width: 100%;

  li {
    display: inline-block;
    width: 100px;
    filter: brightness(0) invert(1);
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;

    @media(min-width: 500px) {
      width: 140px;
    }
  }

  .omo-logo {
    transform: scale(.8);
  }

  .con {
    display: none;
    @media(min-width: 500px) {
      display: revert;
    }
  }
}
</style>
