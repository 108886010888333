import {
  _GettersTree, defineStore, DefineStoreOptions, StateTree, Store,
} from 'pinia';
import { StorageLike } from '@vueuse/core';

interface Utils {
  initWindowDependentStore: <Id extends string, S extends StateTree = {}, G extends _GettersTree<S> = {}, A = {}>(options: DefineStoreOptions<Id, S, G, A>) => Store<Id, S, G, A> | null,
  maybeLocalstorage: StorageLike | undefined
}

export const usePiniaUtils = (): Utils => ({
  /**
   * Provides a nullable pinia store based on the existence of the window.
   *
   * Because of SSR, the application is loaded in contexts where the window
   * object is not available. This can be problematic in many scenarios,
   * especially in the case where localStorage needs to be leveraged.
   *
   * @param options
   *   The options used to define the store.
   */
  initWindowDependentStore: <Id extends string, S extends StateTree = {}, G extends _GettersTree<S> = {}, A = {}>(options: DefineStoreOptions<Id, S, G, A>): Store<Id, S, G, A> | null => (typeof window === 'undefined'
    ? null
    : (defineStore(options))()),

  /**
   * Returns localStorage if it is defined.
   *
   * When the windows does not exist, localStorage does not exist, and vice versa.
   * This is useful when you need to set the type of useStorage() conditionally.
   */
  maybeLocalstorage: typeof localStorage === 'undefined'
    ? undefined
    : localStorage,
});
