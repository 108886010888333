<template>
  <section
    v-if="markup"
    class="marquee"
  >
    <PageContainer>
      <HtmlSanitized
        class="markup"
        :content="markup"
      />
    </PageContainer>
  </section>
</template>

<script setup lang="ts">
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import { useConfigPageApi } from '~/composables/api/non-commerce/config-page';
import { useDataCacheStore } from '~/store/data-cache';
import { LongTextField } from '~/types/json-api/json-api';
import { EitherAxiosResponseData } from '~/composables/api/api-common';

const markup = ref<string>();
const configPageApi = useConfigPageApi();
const dataCache = useDataCacheStore();

const getter = configPageApi.fetchMarquee;
const setter = (eitherMaybeMarqueeField: EitherAxiosResponseData<LongTextField | null>) => pipe(
  eitherMaybeMarqueeField,
  E.fold(
    () => {
    },
    (marquee) => {
      markup.value = marquee?.processed;
    },
  ),
);

dataCache.initPathCache(
  {
    id: 'marquee',
    getter,
    setter,
    includeScopes: false,
    mountDependent: true,
  },
);
</script>

<style lang="scss">
.marquee {
  background: var(--gradient--on-light--warm);
  color: white;
  text-align: center;
  padding: var(--space-xxxs) 0;
  font-size: var(--text-xs);

  a {
    color: var(--color-link);
    text-decoration: underline;

    &:hover {
      filter: none;
      font-weight: 500;
    }
  }

  p {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.markup {
  max-width: 100%;
}
</style>
