<template>
  <Teleport
    v-if="isMounted"
    :to="to"
  >
    <slot />
  </Teleport>
</template>

<script setup lang="ts">
const props = defineProps({
  to: {
    type: String,
    required: true,
  },
});

const isMounted = ref(false);
onMounted(() => isMounted.value = true);
</script>
