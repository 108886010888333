<template>
  <CardsList
    :hx="hx"
    :heading="title"
    :hide-heading="hideHeading"
    :max-cols="maxCols"
  >
    <div
      v-if="$slots.filters"
      class="filters"
    >
      <HeadingHx
        :hx="hx+1"
        class="offscreen"
      >
        Filters
      </HeadingHx>
      <slot name="filters" />
    </div>

    <div>
      <HeadingHx
        :hx="hx+1"
        class="offscreen"
      >
        Products
      </HeadingHx>
      <div
        v-if="showResultsCount"
        id="announce"
        aria-live="polite"
        class="results"
      >
        <template v-if="spinner.isLoading()">
          <BaseSkeleton class="skeleton--results" />
        </template>
        <template v-else>
          <template v-if="productsToRender.length === products.length">
            <p>Showing {{ productsToRender.length }} items</p>
          </template>
          <template v-else>
            <p>Showing {{ productsToRender.length }} of {{ products.length }} items</p>
          </template>
        </template>
      </div>

      <template v-if="spinner.isLoading()">
        <UlUnlisted>
          <li
            v-for="n in skeletonCount"
            :key="n"
          >
            <ProductCardSkeleton />
          </li>
        </UlUnlisted>
      </template>
      <template v-else-if="productsToRender.length === 0">
        <p>{{ noResultsText }}</p>
      </template>
      <template v-else>
        <UlUnlisted>
          <TransitionGroup name="products-group">
            <template v-if="productsToRender.length > 0 ">
              <!--
                Need to use a property of the product as the key and not
                i -- (product, i) in ... -- because the latter causes issues
                with some incorrect data after filtering
              -->
              <li
                v-for="product in productsToRender"
                :key="product.href"
              >
                <ProductCard
                  :title="product.title"
                  :href="product.href"
                  :hx="2"
                  :img-src="product.img || defaultImgSrc"
                  :has-video="product.tags.hasVideo"
                  :has-certificate="product.tags.hasCertificate"
                  :is-maker="product.tags.isMaker"
                  :is-restorer="product.tags.isRestorer"
                  :store-name="product.store.name"
                  :store-id="product.store.id"
                  :price="showPrices ? (product.price || 'Offers only') : null"
                  :blur-price="product.price"
                  :list-price="product.listPrice"
                  :published="product.published"
                  :link-to-preview="linkToPreviews"
                />
              </li>
            </template>
          </TransitionGroup>
        </UlUnlisted>
      </template>
    </div>
  </CardsList>
</template>

<script setup lang="ts">
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import { cloneDeep } from 'lodash';
import { PropType } from 'vue';
import {
  BundlesParams, ProductCardData, ProductType, useProductApi,
} from '~/composables/api/commerce/product';
import defaultImgUrl from '~/assets/default-portrait.png';
import { useLoadingState } from '~/composables/states/loading-state';
import { notEmpty } from '~/common/empty';
import { callWithOptionalAuthHeader, useAuthStore } from '~/store/auth';
import { ImageStyle } from '~/types/enum/enum';
import { ProductData } from '~/types/json-api/commerce';
import { JsonResponseIncluded } from '~/types/json-api/json-api';
import { useDataCacheStore } from '~/store/data-cache';
import { DrupalRole } from '~/types/drupal-common';
import { useProductPrecacheStore } from '~/store/product-precache';

const defaultImgSrc: string = defaultImgUrl;
const props = defineProps({
  hx: {
    type: Number,
    required: false,
    default: 1,
  },
  title: {
    type: String,
    required: false,
    default: undefined,
  },
  bundlesParams: {
    type: Array as () => BundlesParams[],
    required: false,
    // we are unable to properly set a default for this prop,
    // so instead we define a computed ref for this.
  },
  storeId: {
    type: String,
    required: false,
    default: undefined,
  },
  noResultsText: {
    type: String,
    required: false,
    default: 'All of these items are currently out of stock.',
  },
  hideHeading: {
    required: false,
    type: Boolean,
    default: false,
  },
  imageStyle: {
    required: false,
    type: String as () => ImageStyle,
    default: ImageStyle.WIDTH_600,
  },
  limit: {
    required: false,
    type: Number,
    default: -1,
  },
  showResultsCount: {
    type: Boolean,
    required: true,
  },
  products: {
    type: Array as () => ProductCardData[],
    required: false,
    default: undefined,
  },
  linkToPreviews: {
    type: Boolean,
    required: false,
    default: false,
  },
  filterFn: {
    type: Function as PropType<<T>(param: T[]) => T[]>,
    required: true, // or false if not required
    default: (param: any[]) => param, // default implementation
  },
  maxCols: {
    type: Number,
    required: false,
    default: 3,
  },
});

const productApi = useProductApi();
const spinner = useLoadingState();
const authStore = useAuthStore();
const dataCache = useDataCacheStore();
const productPrecache = useProductPrecacheStore();

const skeletonCount = computed(() => (props.limit > 0
  ? props.limit
  : 12));
const showPrices = computed(() => authStore && [DrupalRole.TAX_EXEMPT, DrupalRole.VERIFIED, DrupalRole.ADMINISTRATOR].some((x) => authStore.roles.includes(x)));

// Make sure this is not set to null initially,
// as the template uses the null value to determine
// whether to display the "no results" text.
const products = ref<ProductCardData[]>(props.products || []);

const productsToRender = computed<ProductCardData[]>(() => {
  if (props.filterFn) {
    return props.filterFn(products.value);
  }

  return products.value;
});

// For some reason we cannot correctly set a default for this prop.
const bundlesParams = computed(() => (props.bundlesParams && props.bundlesParams.length > 0
  ? props.bundlesParams
  : [{
    bundles: Object.values(ProductType),
  }]));

type Fetched = E.Either<any, ProductCardData[]>;
type DataSet = Fetched[];
const fetchProducts = async (bpp: BundlesParams): Promise<Fetched> => {
  const queryOptions = {
    bundles: bpp.bundles,
    storeId: props.storeId,
    limit: props.limit > -1
      ? props.limit
      : undefined,
  };
  const params = bpp.params || new DrupalJsonApiParams();
  return pipe(
    await callWithOptionalAuthHeader(
      authStore,
      async (authHeader) => productApi.fetchProducts(
        queryOptions,
        params,
        authHeader,
      ),
      async () => productApi.fetchProducts(
        queryOptions,
        params,
      ),
    ),
    E.fold(
      (e) => E.left(e),
      (response) => {
        const precacheEntries = response.data
          .map((product: ProductData) => productApi.parseProductMetadata(product, response, ImageStyle.PRODUCT_PRIMARY))
          .filter(notEmpty);
        for (const precacheEntry of precacheEntries) {
          // There is still a slight delay in image loading
          // when the product page is visited, because the
          // images aren't preloaded. But having problems with
          // this due to solutions suggesting document or Image(),
          // which aren't available in SSR mode. But still better
          // with the precache at all, so ignoring for now.
          productPrecache.set(precacheEntry.href, precacheEntry);
        }

        // Don't care about the above, just want to pass along the initial response.
        return E.right(response);
      },
    ),
    E.match(
      (e) => E.left(e),
      (response: JsonResponseIncluded<ProductData[]>) => E.right(response.data
        .map((product: ProductData) => productApi.parseCardData(product, response, true, props.imageStyle))
        .filter(notEmpty)),
      // emitLoaded();,
    ),
  );
};
const getter = async (): Promise<DataSet> => spinner.loadWhile(async () => Promise.all(bundlesParams.value.map(fetchProducts)));
const setter = (data: DataSet) => {
  if (data.filter((x) => E.isLeft(x)).length > 0) {
    emit('errored');
  } else {
    products.value = data
      .filter((x) => E.isRight(x))
      .reduce(
        (allProducts: ProductCardData[], x) => ([
          ...(x.right),
          ...allProducts,
        ]),
        [],
      )
      .sort((a: ProductCardData, b: ProductCardData) => {
        const dateA = new Date(a.created).getTime();
        const dateB = new Date(b.created).getTime();
        return dateB - dateA;
      });
    emit('loaded');
  }
};

if (!props.products) {
  dataCache.initPathCache({
    id: [
      'productListing',
      cloneDeep(props),
    ],
    getter,
    setter,
    includeScopes: true,
    mountDependent: true,
  });
}

const emit = defineEmits(['loaded', 'errored']);

defineExpose({ products });
</script>

<style lang="scss" scoped>
.results {
  p {
    margin-top: 0;
  }
}

:deep(.title) {
  // nothing special about this breakpoint, just lazy
  @media(max-width: 600px) {
    margin: 0 !important;
  }
}

.products-group {
  //&-move, /* apply transition to moving elements */
  //&-enter-active,
  //&-leave-active {
  //  transition: all .8s ease;
  //}
  //
  //&-enter-from,
  //&-leave-to {
  //  opacity: 0;
  //  //transform: translateX(30px);
  //  width: 15em;
  //}
  //
  ///* ensure leaving items are taken out of layout flow so that moving
  //   animations can be calculated correctly. */
  //&-leave-active {
  //  position: absolute;
  //}
}

:slotted(.filters) {
  margin: 0 0 var(--space-sm) 0;
  display: flex;
  flex-flow: row wrap;
  gap: var(--space-xxxs);
  font-size: var(--text-sm);
}

.skeleton {
  &--results {
    margin: var(--space-sm) 0;
    width: 12em;
  }
}

</style>
