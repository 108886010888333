import { createConstEnum } from '~/common/enum';

/**
 * A utility type that extracts the value type of a const enum-like object.
 * The type parameter `T` represents a const enum-like object with readonly values.
 *
 * @typeparam T - A const enum-like object with readonly values. This will
 * default to a simple Readonly<Record<keyof T, infer V>> if not provided.
 *
 * @returns The value type of the const enum-like object.
 *
 * @example
 * const MyEnumObject = createConstEnum({
 *   A: 'a',
 *   B: 'b',
 *   C: 'c',
 * });
 *
 * type MyEnumType = EnumType<typeof MyEnumObject>;
 * // MyEnumType type is 'a' | 'b' | 'c'
 */
export type EnumType<T> = T extends Readonly<Record<keyof T, infer V>> ? V : never;

export const ShippingPlugin = createConstEnum({
  SHIPPO: 'shippo',
  SHIPPO_FREE: 'shippo_free',
});
export type ShippingPlugin = EnumType<typeof ShippingPlugin>;

export const ImageStyle = createConstEnum({
  WIDTH_600: 'width_600',
  PORTRAIT_600x800: '3_4_sc_600x800',
  LANDSCAPE_800x600: '4_3_sc_800x600',
  ARTICLE_800: '4_1_5_sc_800x300',
  ARTICLE_1600: '4_1_5_sc_1600x600',
  LARGE: 'large',
  PRODUCT_PRIMARY: 'product_primary',
});

export type ImageStyle = EnumType<typeof ImageStyle>;

export const MetatagTag = createConstEnum({
  LINK: 'link',
  META: 'meta',
});
export type MetatagTag = EnumType<typeof MetatagTag>;

export const NonInstrumentProductTypeTerm = createConstEnum({
  ACCESSORIES: '0e9c59a9-7259-40cf-a7ea-066411d39d13',
});

export type NonInstrumentProductTypeTerm = EnumType<typeof NonInstrumentProductTypeTerm>;

export const CookieName = createConstEnum({
  UPDATE_ROLES: 'directive__roles_updated',
});

export type CookieName = EnumType<typeof CookieName>;

// @see https://github.com/google/libphonenumber/blob/d3d6ffa7eb81c96c47ae3c2ea2c40f750b2cbbdf/javascript/i18n/phonenumbers/phonenumberutil.js#L1111
export const IntlPhoneValidationError = createConstEnum({
  /** The number length matches that of valid numbers for this region. */
  IS_POSSIBLE: 0,
  /** The number has an invalid country calling code. */
  INVALID_COUNTRY_CODE: 1,
  /** The number is shorter than all valid numbers for this region. */
  TOO_SHORT: 2,
  /** The number is longer than all valid numbers for this region. */
  TOO_LONG: 3,
  /**
   * The number length matches that of local numbers for this region only (i.e.
   * numbers that may be able to be dialled within an area, but do not have all
   * the information to be dialled from anywhere inside or outside the country).
   */
  IS_POSSIBLE_LOCAL_ONLY: 4,
  /**
   * The number is longer than the shortest valid numbers for this region,
   * shorter than the longest valid numbers for this region, and does not itself
   * have a number length that matches valid numbers for this region.
   * This can also be returned in the case where
   * isPossibleNumberForTypeWithReason was called, and there are no numbers of
   * this type at all for this region.
   */
  INVALID_LENGTH: 5,
});

export type IntlPhoneValidationError = EnumType<typeof IntlPhoneValidationError>;
